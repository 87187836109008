import React from 'react';
import {Link} from "react-router-dom";
import FontAwesome from "../uiStyle/FontAwesome";
import moment from 'moment';
import 'moment/locale/vi'  // without this line it didn't work
moment.locale('vi')
const InternationalNews = ({title, showMore, className, data,items}) => {
    return (
        <div className={`business3 padding30 white_bg border-radious5 ${className ? className : ''}`}>
            {title ? <h4 className="widget-title">Singapore</h4> : null}
            {items.map((item, i) => (
                <div key={i} className={`single_post post_type12 type20 ${i + 1 < items.length ? 'mb30' : ''}`}>
                    <div className="post_img">
                        <div className="img_wrap  border-radious5">
                            <Link to={item.source.id}>
                                <img src={item.urlToImage} alt="thumb"/>
                            </Link>
                        </div>
                    </div>
                    <div className="single_post_text">
                        <div className="row">
                            <div className="col-9 align-self-cnter">
                                <div className="meta3">
                                    <Link to="/" style={{"textTransform":"uppercase"}}>{item.tag[0]}</Link>
                                    <Link to="/">{moment(item.publishedAt).format('ll')}</Link>
                                </div>
                            </div>
                            <div className="col-3 align-self-cnter">
                                <div className="share_meta4 text-right">
                                    <ul className="inline">
                                        {/* <li><Link to="/"><FontAwesome name="bookmark"/></Link></li> */}
                                        <li><Link to="/"><FontAwesome name="share"/></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <h4><Link to={item.source.id}>{item.title}</Link></h4>
                        <div className="space-10"/>
                        <p className="post-p">{item.description.substring(0, 150)}...</p>
                        <div className="space-10"/>
                        <Link to={item.source.id} className="readmore4">Đọc thêm</Link>
                    </div>
                </div>
            ))}
            {showMore ? <Link to="/" className="showmore">Show more</Link> : null}
        </div>
    );
};

export default InternationalNews;