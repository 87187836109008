import React from 'react';
import { Helmet } from 'react-helmet-async';
export default function SEO({title, description, name, type, url,keywords,image}) {
return (
<Helmet>
{ /* Standard metadata tags */ }
<title>{title}</title>
<link rel="canonical" href={url} />
<meta name='keywords' content={keywords}/>
<meta name='description' content={description} />

{ /* End standard metadata tags */ }
{ /* Facebook tags */ }
{/* <meta property="og:type" content={type} /> */}
<meta property="og:title" content={title} />
<meta property="og:url" content={url} />
<meta property="og:image" content={image} />
<meta property="og:description" content={description} />
{ /* End Facebook tags */ }
{ /* Twitter tags */ }
{/* <meta name="twitter:creator" content={name} />} */}
{/* <meta name="twitter:card" content={type} /> */}
<meta name="twitter:title" content={title} />
<meta name="twitter:description" content={description} />
{/* <meta name="googlebot" content="notranslate"/> */}
{/* <script className='structured-data-list' type="application/ld+json">{structuredJSON}</script> */}

{ /* End Twitter tags */ }
</Helmet>
)
}