import React from 'react';
import FontAwesome from "../uiStyle/FontAwesome";

const FollowUs = ({className='', title}) => {
    return (
        <div className={`follow_box widget mb30 ${className}`}>
            <h2 className="widget-title">{title}</h2>
            <div className="social_shares">
                {/* <Link className="single_social social_facebook" to="https://www.facebook.com/profile.php?id=100088161487448">
                    <span className="follow_icon"><FontAwesome name="facebook-f"/></span>
                     <span className="icon_text">Facebook</span>
                </Link> */}
                <a target ="blank" className="single_social social_facebook" href="https://www.facebook.com/profile.php?id=100088161487448" title="Vsenpai"><span className="follow_icon"><FontAwesome name="facebook-f"/></span>
                     <span className="icon_text">Facebook</span></a>
              
                <a target ="blank" className="single_social social_youtube" href="https://www.youtube.com/channel/UCjnHXY74U6Kg8rUXOtuX9zw" title="Vsenpai"><span className="follow_icon"><FontAwesome name="youtube"/></span>
                     <span className="icon_text">Youtube</span></a>
                {/* <Link className="single_social social_instagram" to="#">
                    <span className="follow_icon"><FontAwesome name="instagram"/></span>
                    <span className="icon_text">instagram</span>
                </Link> */}
                {/* <Link className="single_social social_vimeo" to="#">
                    <span className="follow_icon"><FontAwesome name="vimeo"/></span>
                    34,456 <span className="icon_text">Followers</span>
                </Link> */}
                {/* <Link className="single_social social_medium" to="#">
                    <span className="follow_icon"><FontAwesome name="medium"/></span>
                    34,456 <span className="icon_text">Followers</span>
                </Link> */}
            </div>
        </div>
    );
};

export default FollowUs;