import React from "react";
import { Link } from "react-router-dom";
import FontAwesome from "../uiStyle/FontAwesome";

const WidgetFinanceTwo = (props) => {
  const { title, data, items } = props;
  console.log("items1", items);
  return (
    <div>
      {items ? (
        <div className="finance mb30 white_bg border-radious5 shadow7 padding20">
          <div className="heading">
            <h3 className="widget-title">{title}</h3>
          </div>
          {items.map((item, i) => (
            <div key={i} className="single_post  type18">
              <div className="post_img">
                <div className="img_wrap">
                  <Link to={`/tien-ich/${item.slug}`}>
                    <img src={item.urlToImage} alt="thumb" />
                  </Link>
                </div>
                <span className="batch3 date">{item.tag[0]} </span>
              </div>
              <div className="single_post_text py0">
                <h4>
                  <Link to={`/tien-ich/${item.slug}`}>{item.title}</Link>
                </h4>
                <div className="space-10" />
                <p className="post-p">
                  {item.description.substring(0, 150)}...
                </p>
        
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default WidgetFinanceTwo;
