import React, {Fragment} from 'react';
import {Link} from "react-router-dom";

const FooterNewsCategories = () => {
    return (
        <Fragment>
            <h3 className="widget-title">Khám phá</h3>
            <div className="row">
                <div className="col-lg-6">
                    <ul>
                        <li><Link to="/">Tài chính Nhật bản</Link></li>
                        <li><Link to="/">Đầu tư tài chính tại Nhật</Link></li>
                        <li><Link to="/">Việc làm</Link></li>
                        <li><Link to="/">Kinh nghiệm làm việc</Link></li>
                        <li><Link to="/">Xin việc</Link></li>
                        <li><Link to="/">Thực tập & Học bổng</Link></li>
                    </ul>
                </div>
                <div className="col-lg-6">
                    <ul>
                        <li><Link to="/">Du lịch</Link></li>
                        <li><Link to="/">Quán ăn tại Nhật</Link></li>
                        <li><Link to="/">Làm đẹp</Link></li>
                        <li><Link to="/">Thủ tục hành chính</Link></li>
                        <li><Link to="/">Mở thẻ ngân hàng</Link></li>
                
                    </ul>
                </div>
            </div>
        </Fragment>
    );
};

export default FooterNewsCategories;