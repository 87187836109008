import React, {useState} from 'react';
import {Link} from "react-router-dom";
import FontAwesome from "../uiStyle/FontAwesome";
import Swiper from 'react-id-swiper';
import moment from 'moment';
import 'moment/locale/vi'  // without this line it didn't work
moment.locale('vi')


const BusinessCarousel = (props) => {
    const {items}=props
    console.log("itemsq",items)
    const [swiper, setSwiper] = useState(null);

    const goNext = () => {
        if (swiper !== null) {
            swiper.slideNext();
        }
    };

    const goPrev = () => {
        if (swiper !== null) {
            swiper.slidePrev();
        }
    };

    const params = {
        slidesPerView: 2,
        spaceBetween: 30,
        loop: true,
        breakpoints: {
            1024: {
                slidesPerView: 2,
                spaceBetween: 20
            },
            768: {
                slidesPerView: 1,
                spaceBetween: 0
            },
            320: {
                slidesPerView: 1,
                spaceBetween: 0
            }
        }
    };
    return (
        <div className="business_carousel nav_style4 mb30 ">
            <Swiper getSwiper={setSwiper} {...params}>
                {items.map((item, i) => (
                    <div key={i} className="business_carousel_items white_bg padding20 shadow7">
                        <div className="single_international">
                            <p className="meta before">SỰ KIỆN | {item.location}</p>
                            <h4>
                                <Link to={item.source.id}>
                                    {item.title}</Link></h4>
                            <div className="row">
                                <div className="col-8 align-self-center">
                                    <p>{item.description.slice(0, 100)}...</p>
                                </div>
                                <div className="col-4 align-self-center">
                                    <div className="img_wrap">
                                        <Link to={item.source.id}>
                                            <img src={item.urlToImage} alt="thumb"/>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <ul className="mt20 like_cm meta before">
                                {moment(items[0].updatedAt).format('ll')}
                                {/* <li><Link to="/"><FontAwesome name="heart"/> 6745</Link></li>
                                <li><Link to="/"><FontAwesome name="share"/> 6745</Link></li> */}
                            </ul>
                        </div>
                    </div>
                ))}
            </Swiper>
            <div className="owl-nav">
                <div onClick={goPrev} className="owl-prev"><FontAwesome name="angle-left"/></div>
                <div onClick={goNext} className="owl-next"><FontAwesome name="angle-right"/></div>
            </div>
        </div>
    );
};

export default BusinessCarousel;