import React, { Fragment, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import FontAwesome from "../uiStyle/FontAwesome";
import SidebarMenu from "../SidebarMenu";
import SearchModal from "../SearchModal";
import logo from "../../doc/img/logo/SenPaiLogoW.png";

const menus = [
  {
    id: 1,
    linkText: "Trang chủ",
    link: "/",
  },
  
  {id: 2,
    linkText: "Thị trường Nhật Bản",
    child: true,
    icon: "angle-down",
    submenu: [
      {
        id: 41,
        link: "/jobs",
        linkText: "Thông tin mới",
      },
      {
        id: 42,
        link: "/entertainment",
        linkText: "Tài chính",
      }
    ],
  },
  {id: 3,
    linkText: "Cuộc sống Nhật bản",
    child: true,
    icon: "angle-down",
    submenu: [
      {
        id: 51,
        link: "/jobs",
        linkText: "Công nghệ tiện ích",
      },
      {
        id: 52,
        link: "/entertainment",
        linkText: "Chia sẻ kinh nghiệm",
      }
    ],
  }
  ,
  {id: 4,
    linkText: "Đi làm",
    child: true,
    icon: "angle-down",
    submenu: [
      {
        id: 61,
        link: "/jobs",
        linkText: "Xin việc ",
      },
      {
        id: 62,
        link: "/entertainment",
        linkText: "Chia sẻ kinh nghiệm",
      }
    ],
  },
  {id: 5,
    linkText: "Đi học",
    child: true,
    icon: "angle-down",
    submenu: [
      {
        id: 71,
        link: "/jobs",
        linkText: "Kinh nghiệm học tập ",
      },
      {
        id: 72,
        link: "/entertainment",
        linkText: "Thông tin học bổng & thực tập",
      },
      {
        id: 73,
        link: "/entertainment",
        linkText: "Part-time",
      }
    ],
  },
  {
    id: 6,
    linkText: "Tiện ích",
    link: "/jobs",
  },
  {
    id: 7,
    linkText: "Làm đẹp",
    link: "/home-three/contact",
  },
  

  {id: 10,
    linkText: "Giải trí",
    child: true,
    icon: "angle-down",
    submenu: [
      
      
      {
        id: 90,
        link: "/Restaurant",
        linkText: "Quán ăn",
      },
      {
        id: 91,
        link: "/entertainment",
        linkText: "Du lịch",
      },
      {
        id: 92,
        link: "/entertainment",
        linkText: "Event",
      },]},

  
];
const MainMenuThree = ({ className }) => {
  const [searchShow, setSearchShow] = useState(false);
  const [sideShow, setSideShow] = useState(false);
  return (
    <Fragment>
      <div className={`menu4 ${className ? className : ""}`}>
        <div className="container">
          <div className="main-menu">
            <div className="main-nav clearfix is-ts-sticky">
              <div className="row justify-content-between">
                <nav className="navbar navbar-expand-lg col-lg-12 align-self-center">
                  
                  <div className="site-nav-inner">

                    <div>
                    <button
                      className="navbar-toggler"
                      onClick={() => setSideShow(true)}
                    >
                      <FontAwesome name="bars" />
                   
                    </button>
                   
                    </div>
                   
                    
                    <div
                      id="navbarSupportedContent"
                      className="collapse navbar-collapse navbar-responsive-collapse"
                    >
                      <ul className="nav navbar-nav" id="scroll">
                        {menus.length > 0
                          ? menus.map((item, i) => (
                              <li
                                key={i}
                                className={`
                                                ${item.child ? "dropdown" : ""}
                                                nav-item`}
                              >
                                {item.child ? (
                                  <NavLink
                                    onClick={(e) => e.preventDefault()}
                                    to="/"
                                    className="menu-dropdown"
                                    data-toggle="dropdown"
                                  >
                                    {item.linkText}
                                    <FontAwesome name={item.icon} />
                                  </NavLink>
                                ) : (
                                  <NavLink
                                    to={item.link}
                                    className="menu-dropdown"
                                    data-toggle="dropdown"
                                  >
                                    {item.linkText}
                                    <FontAwesome name={item.icon} />
                                  </NavLink>
                                )}

                                {item.child ? (
                                  <ul className="dropdown-menu" role="menu">
                                    {item.submenu.map((sub_item, i) => (
                                      <li
                                        key={i}
                                        className={`${
                                          sub_item.child
                                            ? "dropdown-submenu"
                                            : null
                                        }
                                                        `}
                                      >
                                        {sub_item.child ? (
                                          <NavLink
                                            onClick={(e) => e.preventDefault()}
                                            to="/"
                                          >
                                            {sub_item.linkText}
                                          </NavLink>
                                        ) : (
                                          <NavLink to={sub_item.link}>
                                            {sub_item.linkText}
                                          </NavLink>
                                        )}
                                        {sub_item.third_menu ? (
                                          <ul className="dropdown-menu">
                                            {sub_item.third_menu.map(
                                              (third_item, i) => (
                                                <li key={i}>
                                                  <NavLink to={third_item.link}>
                                                    {third_item.linkText}
                                                  </NavLink>
                                                </li>
                                              )
                                            )}
                                          </ul>
                                        ) : null}
                                      </li>
                                    ))}
                                  </ul>
                                ) : null}
                              </li>
                            ))
                          : null}
                      </ul>
                    </div>
                    <SidebarMenu
                      className="themeDark"
                      sideShow={sideShow}
                      setSideShow={setSideShow}
                      menus={menus}
                    />
                  </div>
                </nav>
                <div className=" text-right align-self-center my-logo2" >
                        <div style={{width:"250px",  marginLeft:"50px", marginTop:"10px"}}>
                            <Link to="/">
                                <img src={logo} alt="logo"/>
                            </Link>
                        </div>
                    </div>
                {/* <div className="col-lg-2 text-right align-self-center">
                                    <div className="search4" onClick={() => setSearchShow(!searchShow)}><FontAwesome
                                        name="search"/></div>
                                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {searchShow ? (
        <SearchModal setSearchShow={setSearchShow} searchShow={searchShow} />
      ) : null}
    </Fragment>
  );
};

export default MainMenuThree;
