import React, {Fragment} from 'react';
import {Route} from 'react-router-dom';
// import TopBar from "../../components/TopBar";
// import LogoArea from "../../components/LogoArea";
// import MainMenu from "../../components/MainMenu";
// import FooterArea from "../../components/FooterArea";
// import TopBarTwo from "../../components/TopBarTwo";
// import LogoAreaTwo from "../../components/LogoAreaTwo";
// import MainMenuTwo from "../../components/MainMenuTwo";
// import FooterAreaTwo from "../../components/FooterAreaTwo";
import LogoAreaThree from "../../components/LogoAreaThree";
import FooterAreaThree from "../../components/FooterAreaThree";

const PrivateRoute = (props) => {
    const {component: Component, ...rest} = props;
    return (
        <div className={props.parentClass}>
           
                    <Fragment>
                        {/*=== home three ===*/}
                        <LogoAreaThree/>
                    </Fragment>
                  
                  
        

            <Route
                {...rest}
                render={props => (
                    <Component {...props} />
                )}
            />

           
                    <FooterAreaThree/>
                   
        </div>
    )
};
export default PrivateRoute;