import React, {Fragment} from 'react';
import {Link} from "react-router-dom";
import FontAwesome from "../../components/uiStyle/FontAwesome";

import trend31 from '../../doc/img/trending/trend31.jpg';
import trend32 from '../../doc/img/trending/trend32.jpg';
import trend33 from '../../doc/img/trending/trend33.jpg';
import trend34 from '../../doc/img/trending/trend34.jpg';
import trend35 from '../../doc/img/trending/trend35.jpg';
import trend36 from '../../doc/img/trending/trend36.jpg';
import trend37 from '../../doc/img/blog/90652.jpg';
import moment from 'moment';
import 'moment/locale/vi'  // without this line it didn't work
moment.locale('vi')

const TrendingNewsThree = (props) => {

    const {items}=props
    const afterItems = items.slice(1, 10);
    return (
        <div className="white_bg tranding3 padding20 border-radious5 mb30 shadow7">
            <div className="row">
                <div className="col-12">
                    <div className="heading">
                        <h2 className="widget-title">Giải trí</h2>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6">
                    <div className="single_post post_type3  post_type15">
                        <div className="post_img border-radious5">
                            <div className="img_wrap">
                            <a href={`https://vietsenpai.com/giai-tri-nhat-ban/${items[0].slug}`} >
                            <img src={items[0].urlToImage} alt="trend31"/>
                            </a>
                                {/* <Link to={`/giai-tri-nhat-ban/${items[0].slug}`}>
                                    <img src={items[0].urlToImage} alt="trend31"/>
                                </Link> */}
                            </div>
                            <span className="tranding border_tranding"><FontAwesome name="bolt"/></span>
                        </div>
                        <div className="single_post_text">
                            <div className="row">
                                <div className="col-9 align-self-cnter">
                                    <div className="meta3">
                                    <a href={`https://vietsenpai.com/giai-tri-nhat-ban/${items[0].slug}`} >
                                            {items[0].tag[0]}
                                            </a>
                                        <a style ={{"paddingLeft":"5px"}} href={`https://vietsenpai.com/giai-tri-nhat-ban/${items[0].slug}`}>
                                            {moment(items[0].updatedAt).format('ll')}
                                            </a>
                                    </div>
                                </div>
                                <div className="col-3 align-self-cnter">
                                    <div className="share_meta4 text-right">
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="space-5"/>
                            <h4><a href={`https://vietsenpai.com/giai-tri-nhat-ban/${items[0].slug}`}>{items[0].title}</a></h4>
                            <div className="space-10"/>
                            <p className="post-p">{items[0].description.substring(0, 120)}...</p>

                        </div>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="popular_items scroll_bar">
                        {afterItems.map((item, i) => (
                            <Fragment key={i}>
                                <div className="single_post type10 type16 widgets_small mb15">
                                    <div className="post_img">
                                        <div className="img_wrap">
                                        <a href={`https://vietsenpai.com/giai-tri-nhat-ban/${item.slug}`} >

                                                <img src={item.urlToImage} alt="thumb"/>
                                                </a>
                                        </div>
                                    </div>
                                    <div className="single_post_text">
                                        <div className="meta3"><Link to="">{item.tag[0]}</Link>
                                            <a style ={{"paddingLeft":"5px"}} href={`https://vietsenpai.com/giai-tri-nhat-ban/${item.slug}`}>{moment(item.updatedAt).format('ll')}</a>
                                        </div>
                                        <h4><a href={`https://vietsenpai.com/giai-tri-nhat-ban/${item.slug}`}>{item.title}</a></h4>
                                    </div>
                                </div>
                                {i + 1 < afterItems.length ? <Fragment>
                                    <div className="space-5"/>
                                    <div className="border4"/>
                                    <div className="space-15"/>
                                </Fragment> : null}
                            </Fragment>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TrendingNewsThree;