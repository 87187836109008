import React, {Fragment} from "react";
import {toast, ToastContainer} from "react-toastify";
import {connect} from "react-redux";
import Routes from "../__Routes";
import ScrollTopButton from "../../components/ScrollTopButton";
import './Appcss.css'
import { HelmetProvider } from 'react-helmet-async';


const App = (props) => {
    const helmetContext = {};

    const {error, success} = props;
    if (error) toast.error(error);
    if (success) toast.success(success);
    return (
        <HelmetProvider context={helmetContext}>

        <Fragment>

            {/* {error?(
               <div class="loader">

               </div>

            ):( */}
                <div>

                <Routes />
                <ToastContainer position="top-center"/>
                <ScrollTopButton/>

                </div>
            {/* )} */}

        </Fragment>
        </HelmetProvider>

    );
};

const MapStateToProps = state => {
    return {
        error: state.meta.error,
        success: state.meta.success,
    }
};

export default connect(MapStateToProps)(App);
