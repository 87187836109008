import React, { Fragment, useState } from "react";
import MainMenuThree from "../../components/MainMenuThree";
import FollowUs from "../../components/FollowUs";

import { Link, useParams } from "react-router-dom";
import FontAwesome from "../../components/uiStyle/FontAwesome";
import CategoryFour from "../../components/CategoryFour";
import ModalVideo from "react-modal-video";

import quote from "../../doc/img/icon/q4.png";
// import OurBlogSectionTwo from "../../components/OurBlogSectionTwo";

import TravelData from "../../constants/travel-articles";

import moment from "moment";
import "moment/locale/vi"; // without this line it didn't work
import SEO from "../../components/Helmet";
moment.locale("vi");

const PostOneHThreePage = (props) => {
  const {category}=props
  let id = useParams();
  let url =`https://vietsenpai.com/${category}/${id.id}`
  const [vModal, setvModal] = useState(false);
  const [videoId, setVideoId] = useState("");
  // const [item, setData] = useState([TravelData]);
  // const [news, setNews] = useState(null);

  // useEffect(() => {
  //   const getTravelArticles = async () => {
  //     try {
  //       const response = await axios.get(`https://vsenpaibe2.herokuapp.com/${id}`);
  //       setData(response.data.article);
  //       setNews(response.data.news);

  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };
  //   getTravelArticles();
  // }, []);

  const totalInfo = TravelData.filter((item) => item.article.slug === id.id)[0];

  let item = totalInfo.article;
  let news = totalInfo.news;

  const openModalVideo = (e, id) => {
    e.preventDefault();
    setVideoId(id);
    setvModal(true);
  };
  return (
    <div>
      {item && news ? (
        <Fragment>
                      <SEO url ={url} title = {item.title} type = "NewsArticle" description = {item.description} keywords={item.title}  image={item.urlToImage} />

          <MainMenuThree />
          <div className="archives layout3 post post1 padding-top-30">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="bridcrumb">
                    <Link to="/">Home</Link> / {item.category} / {item.title}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-lg-8">
                  <div className="row">
                    <div className="col-6 align-self-center">
                      <div className="page_category">
                        <h4>{item.tag[0]}</h4>
                      </div>
                    </div>
                    {/* <div className="col-6 text-right">
                                    <div className="page_comments">
                                        <ul className="inline">
                                            <li><FontAwesome name="comment"/>563</li>
                                            <li><FontAwesome name="fire"/>536</li>
                                        </ul>
                                    </div>
                                </div> */}
                  </div>

                  <div className="space-30" />
                  <div className="single_post_heading">
                    <h1>{item.title}</h1>
                    <div className="space-10" />
                    <p>{item.description}</p>
                  </div>

                  <div className="space-40" />
                  <div className="border-radious5">
                    <img src={item.picture1.urlToImage} alt="thumb" />
                    <p> {item.picture1.sub}</p>
                  </div>
                  <div className="space-20" />
                  <div className="row">
                    <div className="col-lg-6 col-sm-6  align-self-center">
                      <div className="author">
                        <div className="author_img">
                          <div className="author_img_wrap">
                            <img
                              src="https://dummyimage.com/50x50/b86565/fff&text=Vsenpai"
                              alt="author2"
                            />
                          </div>
                        </div>
                        <Link to="/">
                          {item.author} | {item.source.name}
                        </Link>
                        <ul>
                          <li>
                            <Link to="/">
                              {moment(item.updatedAt).format("ll")}
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* <div className="col-lg-6 col-sm-6  align-self-center">
                      <div className="author_social inline text-right">
                        <Link to="/">
                          <FontAwesome name="share" />
                        </Link>
                      </div>
                    </div> */}
                  </div>
                  <div className="space-20" />
                  {item.type === "post1" ? (
                    <div>
                      {item.paragraph.map((card) =>
                        card.urlToImage ? (
                          card.style === "hor" ? (
                            <div>
                              <div className="border-radious5">
                                <div className="space-20" />
                                <h3>{card.title}</h3>
                                <div className="space-20" />

                                <img src={card.urlToImage} alt={card.sub} />
                                <p className="img_desc">
                                  <span>{card.sub}</span>
                                </p>
                              </div>

                              <div className="space-20" />
                              <p>{card.text} </p>
                              {card.sublink ? (
                                <div className="tags">
                                  <div className="space-20" />

                                  <div className="inline">
                                    <li className="">
                                      <Link to={card.sublink}>{card.title}</Link>
                                    </li>
                                  </div>
                                </div>
                              ) : (
                                <div></div>
                              )}
                            </div>
                          ) : card.style === "half" ? (
                            <div className="row">
                              <h3>{card.title}</h3>
                              <div className="space-20" />

                              <div className="col-md-6">
                                <br />

                                <div className="border-radious5">
                                  <img src={card.urlToImage} alt="smail1" />
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="space-20" />
                                <p>{card.text} </p>
                              </div>
                              {card.sublink ? (
                                <div className="tags">
                                  <div className="space-20" />

                                  <div className="inline">
                                    <li className="">
                                    <Link to={card.sublink}>{card.title}</Link>
                                    </li>
                                  </div>
                                </div>
                              ) : (
                                <div></div>
                              )}
                            </div>
                          ) : card.style === "video" ? (
                            <div>
                              <div className="space-40" />

                              <div className="video_img border-radious5">
                                <Link
                                  onClick={(e) =>
                                    openModalVideo(e, `${card.urlToImage}`)
                                  }
                                  to="/"
                                  className="play_btn"
                                >
                                  <img
                                    src={`https://img.youtube.com/vi/${card.urlToImage}/maxresdefault.jpg`}
                                    alt="big1"
                                  />
                                </Link>
                                <Link
                                  onClick={(e) =>
                                    openModalVideo(e, `${card.urlToImage}`)
                                  }
                                  to="/"
                                  className="video_img_icon play_btn"
                                >
                                  {" "}
                                  <FontAwesome name={"play"} />
                                </Link>
                              </div>
                              <p className="img_desc">
                                <span>{card.sub}</span>
                              </p>
                              <div className="space-40" />
                            </div>
                          ) : (
                            <div className="row">
                              <div className="col-md-5">
                                <br />
                                <div className="border-radious5">
                                  <img src={card.urlToImage} alt="quote" />
                                  <p>{card.sub}</p>
                                </div>
                              </div>
                              <div className="col-md-7">
                                <br />

                                <div className="qhote">
                                  <img src={quote} alt="quote" />
                                  <p>{card.text}</p>
                                  <div className="author">
                                    <div className="author_img">
                                      <div className="author_img_wrap">
                                        <img src={card.avarta} alt="author2" />
                                      </div>
                                    </div>
                                    <Link to="/">{card.name}</Link>
                                    <ul>
                                      <li>{card.job}</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        ) : card.title ? (
                          <div>
                            <div className="space-20" />
                            <h3>{card.title}</h3>
                            <div className="space-20" />

                            <p>{card.text}</p>
                            {card.sublink ? (
                                <div className="tags">
                                  <div className="space-20" />

                                  <div className="inline">
                                    <li className="">
                                    <Link to={card.sublink}>{card.title}</Link>
                                    </li>
                                  </div>
                                </div>
                              ) : (
                                <div></div>
                              )}
                          </div>
                        ) : (
                          <div>
                            <div className="space-20" />
                            <p>{card.text}</p>
                          </div>
                        )
                      )}
                    </div>
                  ) : (
                    <div></div>
                  )}

                  <div className="space-40" />
                  <div className="tags">
                    <ul className="inline">
                      <li className="tag_list">
                        <FontAwesome name="tag" /> tags
                      </li>
                      {item.tag.map((card) => (
                        <li>
                          <Link to="/">{card}</Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="space-40" />
                  {/* <div className="border_black" /> */}
                  {/* <div className="space-40" /> */}
                  {/* <PostOnePagination items ={news}/> */}
                </div>
                <div className="col-md-6 col-lg-4">
                  <FollowUs title="Follow Us" />
                  <div className="banner2 mb30 border-radious5">
                    <Link to="">
                      <img
                        src="https://ascii.jp/img/2022/03/23/3339530/l/92c48eccba681747.jpg"
                        alt="banner4"
                      />
                    </Link>
                  </div>
                  {/* <WidgetFinanceTwo data={financePosts} title="Finance" /> */}
                  {/* <NewsLetter
                    titleClass="white"
                    className="news_letter4 border-radious5"
                  /> */}
                  <CategoryFour />
                </div>
              </div>
            </div>
          </div>
          {/* <div className="space-60" /> */}
          {/* <OurBlogSectionTwo items={news} /> */}
          <div className="space-60" />
          {/* <BlogComment theme={3} /> */}
          {/* <div className="space-60" /> */}
          {/* <BannerSectionThree /> */}
          <ModalVideo
            channel="youtube"
            isOpen={vModal}
            videoId={videoId}
            onClose={() => setvModal(false)}
          />
        </Fragment>
      ) : (
        <div class="loader"></div>
      )}
    </div>
  );
};

export default PostOneHThreePage;
