export default 
[
    {
        "article": {
            "_id": "63ad54b1555b6791158c4eec",
            "source": {
                "name": "Vsenpai"
            },
            "author": "Minh Tâm",
            "title": "Những lễ hội cầu may mắn của người Nhật bản",
            "description": "Hatsumōde: Đây là lần đầu tiên thăm đền thờ trong năm, và nhiều người đến các đền thờ và chùa để cầu xin may mắn trong năm mới.",
            "urlToImage": "https://images.unsplash.com/photo-1628523197648-74f46812c7d7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1742&q=80",
            "slug": "le-hoi-cau-may-man-tai-nhat",
            "url": "AI",
            "publishedAt": "2022-12-28T08:00:40Z",
            "type": "post1",
            "picture1": {
                "urlToImage": "https://images.unsplash.com/photo-1529928226551-5a35bfca43ce?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=450&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTY3Mjk3ODQ0Nw&ixlib=rb-4.0.3&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=730",
                "sub": "Điều ước năm mới tại ngôi đền tại Hiroshima. Photo: Daniel Gregoire"
            },
            "paragraph": [
                {
                    "title": "Lễ hội Hatsumōde (初詣) ",
                    "text": "Hatsumōde (初詣) là một lễ hội đầu năm truyền thống tại Nhật Bản, được tổ chức trong những ngày đầu năm mới. Nó là lễ hội lâu đời và phổ biến nhất tại Nhật Bản với sự tham gia của nhiều người tham gia từ khắp nơi trên đất nước này.",
                    "urlToImage": null
                },
                {
                    "text": "Trong lễ hội Hatsumōde, người dân thường đến thăm các đền thờ Shintō và thờ Phật để cầu nguyện cho một năm mới may mắn và khỏe mạnh. Họ cũng thường mua các lễ phẩm và quà tặng cho gia đình và bạn bè. Nhiều người cũng dùng lễ hội này làm cơ hội để gặp gỡ và gặp mặt những người bạn cũ và gia đình",
                    "style": "hor",
                    "urlToImage": "https://images.unsplash.com/photo-1507693595546-0512d61de389?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1514&q=80",
                    "sub": "Ảnh chụp tại đền Senso-ji, Tokyo. Photo: Jezael Melgoza"
                },
                {
                    "text": "Lễ hội Hatsumōde được tổ chức tại các đền thờ và chùa trên toàn quốc, nhưng những địa điểm đặc biệt như đền Meiji-jingū tại Tokyo và đền Fushimi Inari tại Kyoto là những nơi có số lượng người tham dự lớn nhất. Lễ hội Hatsumōde thường bắt đầu vào ngày 1 hoặc 2 đầu năm mới và kéo dài cho đến hết ngày 3 hoặc 4.",
                    "urlToImage": null
                },
                {
                    "text": "Lễ hội Năm Mới Omiya là một lễ hội được tổ chức tại thành phố Omiya, Nhật Bản, vào ngày 1 tháng 1 mỗi năm. Lễ hội này được tổ chức tại khu vườn bảo tàng Omiya Bonsai Art Museum và có nhiều hoạt động vui chơi và tham gia dành cho cộng đồng, bao gồm trình diễn nhạc, trình diễn vũ điệu, và các hoạt động thể dục. Ngoài ra, còn có các buổi diễn nghệ thuật và trình diễn cây bonsai.",
                    "title": "Lễ hội năm mới Omiya ",
                    "style": "hor",
                    "urlToImage": "https://www.stib.jp/saitamacity-visitorsguide/wp-saitama/wp-content/uploads/2020/02/nakasendofest_1.jpg",
                    "sub": "Ảnh rước kiệu tại lễ hội. Photo: Sataima city"
                },
                {
                    "text": "Lễ hội năm mới Asakusa: Lễ hội này được tổ chức tại Asakusa, một trung tâm du lịch quan trọng của thành phố, Tokyo, và có nhiều hoạt động truyền thống khác nhau, bao gồm kéo mikoshi (đền thờ di động) và biểu diễn âm nhạc và múa truyền thống.",
                    "urlToImage": "https://cdn.contexttravel.com/image/upload/c_fill,q_60,w_2400/azdivrqjosdxqza49os5.jpg",
                    "sub": "Ảnh lễ hội tại Asakusa. Photo: Context travel",
                    "title": "Lễ hội năm mới tại Asakusa ",
                    "style": "hor"
                },
                {
                    "text": "Lễ hội Nagasaki Kunchi là một lễ hội được tổ chức tại thành phố Nagasaki, Nhật Bản, vào ngày 7, 8 và 9 tháng 10 mỗi năm. Lễ hội này được tổ chức tại đền thờ Suwa, một đền thờ đứng số một tại Nagasaki. Lễ hội bắt đầu vào ngày 7 tháng 10 với buổi diễn hòa nhạc và trình diễn vũ điệu. Ngày 8 và 9 tháng 10 là hai ngày chính của lễ hội, và có nhiều hoạt động vui chơi và tham gia dành cho cộng đồng, bao gồm trình diễn nhạc, trình diễn diễu hành. Lễ hội Nagasaki Kunchi được coi là một trong những lễ hội điển hinhg của Nhật Bản.",
                    "title": "Lễ hội Nagasaki Kunchi ",
                    "urlToImage": "https://www.japan-guide.com/g17/4411_02.jpg",
                    "sub": "Ảnh lễ hội tại Nagasaki. Photo: japan-guide",
                    "style": "hor"
                },
                {
                    "text": "Lễ hội Gion là một lễ hội được tổ chức tại thành phố Kyoto, Nhật Bản, vào khoảng từ ngày 17 đến ngày 24 tháng 7 mỗi năm. Lễ hội này được tổ chức tại khu vực Gion, một khu vực du lịch quan trọng của thành phố, và có nhiều hoạt động vui chơi và tham gia dành cho cộng đồng.",
                    "title": "Lễ hội Gion ",
                    "urlToImage": "https://p.potaufeu.asahi.com/64e1-p/picture/26790281/2a6b0415a6f98e0cc87b01c2c47d0f13.jpg",
                    "sub": "Ảnh lễ hội Gon tại Kyoto. Photo: Ashahi Shumbun",
                    "style": "hor"
                },
                {
                    "text": "Trong các lễ hội cầu may cho năm mới, người dân Nhật Bản thường đến các đền thờ và tôn giáo để cầu may cho một năm mới may mắn và thành công. Còn các người nước ngoài thường đến tham gia các hoạt động vui chơi và trải nghiệm văn hóa Nhật Bản. Những lễ hội này là một cơ hội tuyệt vời để trải nghiệm văn hóa Nhật Bản và tham gia vào các hoạt động thú vị trong một không gian truyền thống.",
                    "urlToImage": null
                }
            ],
            "tag": [
                "Du lịch",
                "Lễ hội Nhật bản",
                "Năm mới",
                "Lễ hội Nagasaki Kunchi"
            ],
            "category": "Giải trí",
            "urlToVideo": "youtube.com",
            "responsibilities": [],
            "language": [],
            "roleSummary": [],
            "benefit": [],
            "createdAt": "2022-12-29T08:49:53.769Z",
            "updatedAt": "2022-12-29T08:49:53.769Z",
            "__v": 0
        },
        "news": [
            {
                "_id": "63a436190144104629286e7e",
                "source": {
                    "name": "Post Magazine",
                    "id": "63a436190144104629286e7c"
                },
                "author": "A KAY",
                "title": "Mở tài khoản ngân hàng tại Nhật",
                "description": "Mở một tài khoản ngân hàng trực tuyến tại Nhật Bản thường khá dễ dàng, nhưng có một số yêu cầu cần đảm bảo bạn có thể hoàn thành trước khi bắt đầu quá trình mở tài khoản.",
                "tag": [
                    "Tiện ích",
                    "Nhật bản"
                ],
                "urlToImage": "https://i.ytimg.com/vi/lIGaKhFWkEg/maxresdefault.jpg",
                "url": "AI",
                "category": "News",
                "ad": true,
                "createdAt": "2022-12-22T10:59:57.084Z",
                "updatedAt": "2022-12-22T10:59:57.084Z",
                "__v": 0
            },
            {
                "_id": "63a436b10144104629286e9a",
                "source": {
                    "name": "Post Magazine",
                    "id": "63a436b10144104629286e98"
                },
                "author": "A KAY",
                "title": "Quán ăn đọc đáo tại Nhật",
                "description": "Mở một tài khoản ngân hàng trực tuyến tại Nhật Bản thường khá dễ dàng, nhưng có một số yêu cầu cần đảm bảo bạn có thể hoàn thành trước khi bắt đầu quá trình mở tài khoản.",
                "tag": [
                    "Tiện ích",
                    "Nhật bản"
                ],
                "urlToImage": "https://images.unsplash.com/photo-1476055439777-977cdf3a5699?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                "url": "AI",
                "category": "News",
                "ad": true,
                "createdAt": "2022-12-22T10:59:29.891Z",
                "updatedAt": "2022-12-22T10:59:29.891Z",
                "__v": 0
            },
            {
                "_id": "63a432340144104629286e7a",
                "source": {
                    "name": "Post Magazine",
                    "id": "63a432340144104629286e78"
                },
                "author": "A KAY",
                "title": "Du lịch Nhật bản mùa đông",
                "description": "Nhật Bản có rất nhiều địa điểm du lịch tuyệt vời trong mùa đông. Một số địa điểm du lịch đáng chú ý trong mùa đông bao gồm:",
                "tag": [
                    "Nhật bản",
                    "Du lịch"
                ],
                "urlToImage": "https://images.unsplash.com/photo-1540959733332-eab4deabeeaf?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1494&q=80",
                "url": "AI",
                "publishedAt": "2022-12-22T08:00:40Z",
                "category": "News",
                "ad": true,
                "createdAt": "2022-12-22T10:53:43.648Z",
                "updatedAt": "2022-12-22T10:53:43.648Z",
                "__v": 0
            }
        ]
    }
,{
    "article": {
        "benefit": [],
        "_id": "63a42b9e3f613fff8f2bea40",
        "source": {
            "name": "Post Magazine"
        },

        "author": "A KAY",
        "title": "Du lịch Nhật bản mùa đông",
        "description": "Nhật Bản có rất nhiều địa điểm du lịch tuyệt vời trong mùa đông. Một số địa điểm du lịch đáng chú ý trong mùa đông bao gồm:",
        "urlToImage": "https://images.unsplash.com/photo-1540959733332-eab4deabeeaf?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1494&q=80",
        "url": "AI",
        "publishedAt": "2022-12-22T08:00:40Z",
        "content": "Live from the New York Stock Exchange, TheStreet's J.D. Durkin and Martin Baccardax discuss the major markets stories that ... \r\n#martinbaccardax#thestreet#jddurkin\r\n This story appeared first on you… [+20 chars]",
        "type": "post1",
        "picture1": {
            "name": "A KAY",
            "urlToImage": "https://images.unsplash.com/photo-1540959733332-eab4deabeeaf?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1494&q=80",
            "sub": "Thành phố Tokyo về đêm. Photo: Jezael Melgoza"
        },
        "paragraph": [
            {
                "text": "Sapporo: Sapporo là thành phố lớn nhất trên đảo Hokkaido, cùng với mùa đông lạnh giá, nó còn là nơi tổ chức lễ hội tuyết Sapporo Snow Festival mỗi năm. Lễ hội này diễn ra vào tháng 1 và có nhiều tác phẩm tuyết đẹp và hoạt động vui chơi khác như trượt tuyết, đi xe mô tô tuyết, và đi xe đạp tuyết.",
                "urlToImage": "https://images.unsplash.com/photo-1565210579838-4b7e88345ca0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80",
                "sub": "Thành phố Sapporo dưới tuyết. Photo: Jezael Melgoza",
                "style": "hor"
            },
            {
                "text": "Kyoto: Kyoto là một thành phố lịch sử cổ xưa có rất nhiều địa điểm du lịch nổi tiếng như Kinkaku-ji, Fushimi Inari Shrine, và Ginkaku-ji. Mùa đông là mùa hoa sakura rụng và mùa hoa mai nở, nên có rất nhiều hoa đẹp để thưởng thức.",
                "urlToImage": null
            },
            {
                "text": "Hakone: Hakone là một khu du lịch nổi tiếng ở khu vực Kanto, cách Tokyo khoảng 1 tiếng đồng hồ. Nó có nhiều địa điểm du lịch như Onsen (nơi tắm nóng), khách sạn Onsen, và thác Owakudani. Trong mùa đông, có rất nhiều hoạt động vui chơi như trượt tuyết và đi xe mô",
                "style": "hor",
                "urlToImage": "https://images.unsplash.com/photo-1566007971063-f0284fa3808c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
                "sub": "Thành phố Hokane yên bình. Photo: Guillermo Pérez"
            },
            {
                "text": "Niseko là một khu nghỉ mát trượt tuyết nằm ở đảo Hokkaido và được biết đến với tuyết rất mịn và các đồi trượt tuyết tuyệt vời. Khu nghỉ mát cũng có rất nhiều nơi tắm nóng và khách sạn Onsen cho khách du lịch thư giãn sau một ngày trên đồi trượt tuyết.",
                "urlToImage": null
            },
            {
                "text": "Tokyo là thủ đô và thành phố lớn nhất của Nhật Bản và được biết đến với đường phố rộng rãi, đêm đời sôi động và nhiều địa điểm văn hóa. Trong mùa đông, thành phố được trang hoàng bởi ánh đèn và trang trí Giáng sinh, khiến nó trở thành một nơi vui nhộn và thú vị để du lịch.",
                "urlToImage": null
            },
            {
                "text": "Mùa đông tại Nhật Bản có thể rất lạnh, nên nếu bạn muốn du lịch tại Nhật Bản vào thời điểm đó, hãy chuẩn bị áo khoác và quần áo lót lý tưởng.",
                "urlToImage": null
            }
        ],
        "tag": [
            "Nhật bản",
            "Du lịch",
            "Mùa đông"
        ],
        "category": "Travel",
        "urlToVideo": "youtube.com",
        "responsibilities": [],
        "language": [],
        "roleSummary": [],
        "createdAt": "2022-12-22T10:04:14.226Z",
        "updatedAt": "2022-12-22T10:04:14.226Z",
        "__v": 0
    },
    "news": [
        {
            "_id": "63a436190144104629286e7e",
            "source": {
                "name": "Post Magazine",
                "id": "63a436190144104629286e7c"
            },
            "author": "A KAY",
            "title": "Mở tài khoản ngân hàng tại Nhật",
            "description": "Mở một tài khoản ngân hàng trực tuyến tại Nhật Bản thường khá dễ dàng, nhưng có một số yêu cầu cần đảm bảo bạn có thể hoàn thành trước khi bắt đầu quá trình mở tài khoản.",
            "tag": [
                "Tiện ích",
                "Nhật bản"
            ],
            "urlToImage": "https://i.ytimg.com/vi/lIGaKhFWkEg/maxresdefault.jpg",
            "url": "AI",
            "category": "News",
            "ad": true,
            "createdAt": "2022-12-22T10:59:57.084Z",
            "updatedAt": "2022-12-22T10:59:57.084Z",
            "__v": 0
        },
        {
            "_id": "63a436b10144104629286e9a",
            "source": {
                "name": "Post Magazine",
                "id": "63a436b10144104629286e98"
            },
            "author": "A KAY",
            "title": "Quán ăn đọc đáo tại Nhật",
            "description": "Mở một tài khoản ngân hàng trực tuyến tại Nhật Bản thường khá dễ dàng, nhưng có một số yêu cầu cần đảm bảo bạn có thể hoàn thành trước khi bắt đầu quá trình mở tài khoản.",
            "tag": [
                "Tiện ích",
                "Nhật bản"
            ],
            "urlToImage": "https://images.unsplash.com/photo-1476055439777-977cdf3a5699?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
            "url": "AI",
            "category": "News",
            "ad": true,
            "createdAt": "2022-12-22T10:59:29.891Z",
            "updatedAt": "2022-12-22T10:59:29.891Z",
            "__v": 0
        },
        {
            "_id": "63a432340144104629286e7a",
            "source": {
                "name": "Post Magazine",
                "id": "63a432340144104629286e78"
            },
            "author": "A KAY",
            "title": "Du lịch Nhật bản mùa đông",
            "description": "Nhật Bản có rất nhiều địa điểm du lịch tuyệt vời trong mùa đông. Một số địa điểm du lịch đáng chú ý trong mùa đông bao gồm:",
            "tag": [
                "Nhật bản",
                "Du lịch"
            ],
            "urlToImage": "https://images.unsplash.com/photo-1540959733332-eab4deabeeaf?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1494&q=80",
            "url": "AI",
            "publishedAt": "2022-12-22T08:00:40Z",
            "category": "News",
            "ad": true,
            "createdAt": "2022-12-22T10:53:43.648Z",
            "updatedAt": "2022-12-22T10:53:43.648Z",
            "__v": 0
        }
    ]
}]