import React, {Fragment} from 'react';
import MainMenuThree from "../../components/MainMenuThree";
import HeroArea from "../../components/HeroArea";
import TrendingNewsThree from "../../components/TrendingNewsThree";
import BusinessCarousel from "../../components/BusinessCarousel";
import BusinessImageCarousel from "../../components/BusinessImageCarousel";
import WidgetFinanceTwo from "../../components/WidgetFinanceTwo";
import {Link} from "react-router-dom";
import VIdeoNewsSection from "../../components/VIdeoNewsSection";
import InternationalNews from "../../components/InternationalNews";
import ScienceNews from "../../components/ScienceNews";
import FollowUs from "../../components/FollowUs";
import WidgetOpinionNews from "../../components/WidgetOpinionNews";
import NewsLetter from "../../components/NewsLetter";
import CategoryFour from "../../components/CategoryFour";

import banner42 from '../../doc/img/bg/ads.png';
import finance41 from '../../doc/img/finance/finance41.jpg';
import international41 from '../../doc/img/international/international41.jpg';
import international42 from '../../doc/img/international/international42.jpg';
import international43 from '../../doc/img/international/international43.jpg';
import international44 from '../../doc/img/international/international44.jpg';
import international45 from '../../doc/img/international/international45.jpg';
import { useState } from 'react';
import homePageData from "../../constants/home-page-data"
import SEO from '../../components/Helmet';
const internationalPosts = [
    {
        photo: international41,
        title: 'Investors explain COVID-19’s impact on consumer startups',
        description: 'The property, complete with 30-seat screening from room, a 100-seat amphitheater and a swimming pond with',
    },
    {
        photo: international42,
        title: 'Investors explain COVID-19’s impact on consumer startups',
        description: 'The property, complete with 30-seat screening from room, a 100-seat amphitheater and a swimming pond with',
    },
    {
        photo: international43,
        title: 'Investors explain COVID-19’s impact on consumer startups',
        description: 'The property, complete with 30-seat screening from room, a 100-seat amphitheater and a swimming pond with',
    },
    {
        photo: international44,
        title: 'Investors explain COVID-19’s impact on consumer startups',
        description: 'The property, complete with 30-seat screening from room, a 100-seat amphitheater and a swimming pond with',
    },
    {
        photo: international45,
        title: 'Investors explain COVID-19’s impact on consumer startups',
        description: 'The property, complete with 30-seat screening from room, a 100-seat amphitheater and a swimming pond with',
    },
    {
        photo: international43,
        title: 'Investors explain COVID-19’s impact on consumer startups',
        description: 'The property, complete with 30-seat screening from room, a 100-seat amphitheater and a swimming pond with',
    },
];


const financePosts2 = [
    {
        photo: finance41,
        title: 'Copa America: Luis Suarez from devastated US',
        description: 'The property, complete with seates screening from room amphitheater pond with sandy'
    },
    {
        photo: finance41,
        title: 'Copa America: Luis Suarez from devastated US',
        description: 'The property, complete with seates screening from room amphitheater pond with sandy'
    },
    {
        photo: finance41,
        title: 'Copa America: Luis Suarez from devastated US',
        description: 'The property, complete with seates screening from room amphitheater pond with sandy'
    },
];

const HomePageThree = () => {
    const [items, setData] =useState(homePageData)

    // setData(homePageData)
    // useEffect(() => {
    //   const getTravelArticles = async ()  => {
    //       try {
    //         const response = await axios.get(`https://vsenpaibe2.herokuapp.com/homepage-articles`);
            
    //   setData(response.data)
  
  
    //       } catch (error) {
    //         console.error(error);
    //       }
    //     }
    //   getTravelArticles();
   
    // }, []);


    return (
        <div>
        {items && items.working? (
        <Fragment>
            <SEO title = "Báo tiếng Việt tại Nhật Bản" type = "NewsArticle" description = "Vsenpai tin tức mới nhất - Thông tin nhanh &amp; chính xác được cập nhật. Đọc báo tin tức online Nhật bản &amp; Nhật bản chia sẻ kinh nghiệm, pháp luật, kinh doanh cho người Việt Nam tại Nhật bản,..." name = "tina" keywords="Vsenpai, tin tức, tin Nhật bản bằng tiếng Việt, việc làm cho người Việc, du lịch Nhật bản, doc bao" />
            <div className="wrapper_welcome">
                <MainMenuThree className="home4menu"/>
                <HeroArea items={items.top5news} />
                <div className="bg4">
                    <div className="space-60"/>
                    <div className="total3 mb30">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 col-xl-8">
                                    <TrendingNewsThree items={items.travel}/>
                                    <BusinessCarousel  items={items.event}/>
                                    <BusinessImageCarousel  items={items.working}/>
                                    <div className="banner_area mb30 xs-mt60">
                                        <Link to="/">
                                            <img src={banner42} alt="banner42"/>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-4 d-md-none d-xl-block">
                                <div className="banner2 mb30 border-radious5">
                                        <Link to="/">
                                            <img src="https://corp.rakuten.co.jp/privacy/en/assets/img/og_image.png" alt="banner4"/>
                                        </Link>
                                    </div>
                                    <WidgetFinanceTwo items={items.tienich}  title="Đời sống Nhật bản"/>
                                   
                                </div>
                            </div>
                        </div>
                        <VIdeoNewsSection items={items.financial}/>
                    </div>
                    <div className="inernational4">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 col-xl-8">
                                    <InternationalNews data={internationalPosts}  items={items.personalf} className="mb30" title={true} showMore={true}/>
                                    <div className="banner_area mb30 xs-mt60">
                                        <Link to="/">
                                            <img src={banner42} alt="banner42"/>
                                        </Link>
                                    </div>
                                    <ScienceNews items={items.studyAb}/>
                                    {/* <div className="row">
                                        <div className="col-md-6">
                                            <SportsNewsTwo/>
                                        </div>
                                        <div className="col-md-6">
                                            <GalleryCarousel/>
                                            <WidgetTabThree/>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="col-md-12 col-xl-4">
                                    <div className="row">
                                        <div className="col-lg-6 col-xl-12 col-md-6">
                                        <FollowUs className="padding20 white_bg shadow7" title="Follow Us"/>

                                        <WidgetOpinionNews/>
                                        <CategoryFour/>

                                        </div>
                                        <div className="col-md-6 col-xl-12">
                                            <NewsLetter titleClass="white" className="news_letter4 border-radious5"/>
                                            <WidgetFinanceTwo data={financePosts2} title="Inernational"/>
                                            {/* <div className="banner2 mb30 border-radious5  d-md-none d-xl-block">
                                                <Link to="/">
                                                    <img src={banner4} alt="banner4"/>
                                                </Link>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="space-60"/>
                </div>
            </div>
        </Fragment>
        ) : (
<div class="loader">
        </div>            )}
          </div>
        
    );
    
};

export default HomePageThree;