export default 
{
    "article": {
        "_id": "63ad9d10b8239e3b7873923d",
        "source": {
            "name": "Vsenpai Magazine"
        },
        "author": "Minh tâm",
        "title": "Mở tài khoản ngân hàng tại Nhật có khó không?",
        "description": "Mở tài khoản ngân hàng tại Nhật có thể là một trong những thách thức lớn nhất khi vừa tới Nhật bản mà bạn có thể gặp phải. Ngân hàng tại nhật thường chỉ mở từ 11h trưa đến 3h chiều ngày hàng chính, nên bạn nên kiểm tra trên google chi nhánh bạn muốn đến để tới vào thời điểm ngân hàng mở cửa.",
        "urlToImage": "https://i.pinimg.com/originals/82/80/68/82806895340fa9e5f71f1c6252321692.png",
        "slug": "mo-tai-khoan-ngan-hang-tai-nhat-ban",
        "url": "Tina",
        "publishedAt": "2022-12-22T08:00:40Z",
        "type": "post1",
        "picture1": {
            "name": "",
            "urlToImage": "https://i.pinimg.com/originals/82/80/68/82806895340fa9e5f71f1c6252321692.png",
            "sub": "Quầy mở thẻ tại ngân hàng tại Nhật bản."
        },
        "paragraph": [
            {
                "text": "Mở tài khoản ngân hàng tại Nhật có thể là một trong những thách thức lớn nhất khi vừa tới Nhật bản mà bạn có thể gặp phải. Mặc dù sau đợt dịch kéo dài đã khiến các ngân hàng Nhật Bản phải chuyển ngân hàng trực tuyến nhưng quá trình này vẫn diễn một cách chậm chạp, và gây khó dễ cho người nước ngoài đặc biệt về ngôn ngữ. Nhiều việc như mở tài khoản ngân hàng vẫn yêu cầu mọi tới tận chi nhánh để làm thủ tục và bằng hoàn toàn bằng tiếng Nhật.",
                "urlToImage": null
            },
            {
                "text": "Mặc dù nhiều nơi trên thế giới có thể dùng app để tạo tài khoản ngân hàng, nhưng tại nhiều nơi tại Nhật, họ vẫn mong muốn khách hàng của họ sử dụng con dấu cá nhân 印鑑 (いんかん)hay còn gọi là Hanko (ハンコ) , con dấu này như là chữ ký của bạn và để dùng đăng ký với ngân hàng.",
                "style": "hor",
                "title": "Con dấu Hanko (ハンコ)",
                "urlToImage": "https://i.pinimg.com/564x/df/67/5d/df675d70699e6f44b5d521fd061ed15d.jpg",
                "sub": "Con dấu chữ ký tại nhật"
            },
            {
                "text": "1.Thẻ cư trú (zairyu card). Thẻ này bạn sẽ nhận ngay tại sân bay khi lần đầu tiên nhập cảnh tại Nhật.",
                "title": " Những giấy tờ cần chuẩn bị trên tay",
                "urlToImage": null
            },
            {
                "text": "2.Hộ chiếu",
                "urlToImage": null
            },
            {
                "text": "2.Hộ chiếu",
                "urlToImage": null
            },
            {
                "text": "3.Con dấu 印鑑 (いんかん)hay còn gọi là Hanko (ハンコ)",
                "urlToImage": null
            },
            {
                "text": "4.Số điện thoại liên lạc tại Nhật. Nếu bạn không chưa có số thì có thể dùng số của người thân. Tùy thuộc vào độ khó của ngân hàng. Nhiều ngân hàng chỉ mở cho người đã có số điện thoại cá nhân rồi.",
                "urlToImage": null
            },
            {
                "text": "4.Số điện thoại liên lạc tại Nhật. Nếu bạn không chưa có số thì có thể dùng số của người thân. Tùy thuộc vào độ khó của ngân hàng. Nhiều ngân hàng chỉ mở cho người đã có số điện thoại cá nhân rồi.",
                "urlToImage": null
            },
            {
                "text": "5.Chứng minh bạn sẽ ở Nhật lâu dài",
                "urlToImage": null
            },
            {
                "text": "Một số nơi có hỗ trợ giấy tờ cách phiên dịch bằng tiếng nước ngoài nhưng nhiều trường hợp ngân hàng sẽ không hỗ trợ mở tài khoản cho bạn nếu bạn không nói và viết được tiếng Nhật, vì vậy tốt nhất bạn cần phải có vốn từ tiếng Nhật cơ bản N4 để quá trình mở tài khoản nhanh chóng và thuận tiện.",
                "urlToImage": null
            },
            {
                "text": "Ngân hàng tại nhật thường chỉ mở từ 11h trưa đến 3h chiều ngày hàng chính,  nên bạn nên kiểm tra qua google chi nhánh bạn muốn đến để tới vào thời điểm ngân hàng mở cửa.",
                "title": "Lưu ý:",
                "urlToImage": null
            },
            {
                "text": "Có những ngân hàng sẽ không hỗ trợ mở tài khoản cho học sinh, và người chưa ở Nhật bản đủ 6 tháng.",
                "urlToImage": null
            },
            {
                "text": "Bạn sẽ được hỏi có muốn cuốn sổ ngân hàng (cuốn sổ để ghi chép tiền vào-ra tài khoản của bạn) nếu bạn trả thêm phí. Cuốn sổ này có thể dùng như thẻ rút tiền nhưng nó khá bất tiện vì nó khá to, và nếu bạn lỡ có làm mất thì thật nguy hiểm vì người nhận được sẽ biết thông tin ngân hàng của bạn và số tiền hiện có.",
                "urlToImage": null
            },
            {
                "text": "Nhật bản là một quốc gia phát triển, nhưng hệ thống ngân hàng vẫn còn khá lạc hậu. Nhiều nơi trên thế giới đã dần bỏ hết những sổ ngân hàng hay con dấu thì tại Nhật vẫn còn tranh cãi có nên bỏ hay không. Việc mở tài khoản ngân hàng vẫn khá bất tiện cho người nước ngoài nên nếu bạn vẫn chưa tự tin với vốn ngoại ngữ của mình thì nên đi cùng với người có kinh nghiệm và thuần thục tiếng Nhật.",
                "urlToImage": null
            }
        ],
        "tag": [
            "Tiện ích",
            "Mở tài khoản ngân hàng",
            "Chuyển tiền"
        ],
        "category": "Tiện ích",
        "urlToVideo": "youtube.com",
        "responsibilities": [],
        "language": [],
        "roleSummary": [],
        "benefit": [],
        "createdAt": "2022-12-29T13:58:40.285Z",
        "updatedAt": "2022-12-29T13:58:40.285Z",
        "__v": 0
    },
    "news": [
        {
            "_id": "63a436190144104629286e7e",
            "source": {
                "name": "Post Magazine",
                "id": "63a436190144104629286e7c"
            },
            "author": "A KAY",
            "title": "Mở tài khoản ngân hàng tại Nhật",
            "description": "Mở một tài khoản ngân hàng trực tuyến tại Nhật Bản thường khá dễ dàng, nhưng có một số yêu cầu cần đảm bảo bạn có thể hoàn thành trước khi bắt đầu quá trình mở tài khoản.",
            "tag": [
                "Tiện ích",
                "Nhật bản"
            ],
            "urlToImage": "https://i.ytimg.com/vi/lIGaKhFWkEg/maxresdefault.jpg",
            "url": "AI",
            "category": "News",
            "ad": true,
            "createdAt": "2022-12-22T10:59:57.084Z",
            "updatedAt": "2022-12-22T10:59:57.084Z",
            "__v": 0
        },
        {
            "_id": "63a436b10144104629286e9a",
            "source": {
                "name": "Post Magazine",
                "id": "63a436b10144104629286e98"
            },
            "author": "A KAY",
            "title": "Quán ăn đọc đáo tại Nhật",
            "description": "Mở một tài khoản ngân hàng trực tuyến tại Nhật Bản thường khá dễ dàng, nhưng có một số yêu cầu cần đảm bảo bạn có thể hoàn thành trước khi bắt đầu quá trình mở tài khoản.",
            "tag": [
                "Tiện ích",
                "Nhật bản"
            ],
            "urlToImage": "https://images.unsplash.com/photo-1476055439777-977cdf3a5699?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
            "url": "AI",
            "category": "News",
            "ad": true,
            "createdAt": "2022-12-22T10:59:29.891Z",
            "updatedAt": "2022-12-22T10:59:29.891Z",
            "__v": 0
        },
        {
            "_id": "63a432340144104629286e7a",
            "source": {
                "name": "Post Magazine",
                "id": "63a432340144104629286e78"
            },
            "author": "A KAY",
            "title": "Du lịch Nhật bản mùa đông",
            "description": "Nhật Bản có rất nhiều địa điểm du lịch tuyệt vời trong mùa đông. Một số địa điểm du lịch đáng chú ý trong mùa đông bao gồm:",
            "tag": [
                "Nhật bản",
                "Du lịch"
            ],
            "urlToImage": "https://images.unsplash.com/photo-1540959733332-eab4deabeeaf?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1494&q=80",
            "url": "AI",
            "publishedAt": "2022-12-22T08:00:40Z",
            "category": "News",
            "ad": true,
            "createdAt": "2022-12-22T10:53:43.648Z",
            "updatedAt": "2022-12-22T10:53:43.648Z",
            "__v": 0
        }
    ]
}