export default 
{
    "working": [
        {
            "_id": "639d13d223a2d82ceb8b61b9",
            "source": {
                "name": "Post Magazine",
                "id": "639d13d223a2d82ceb8b61b7"
            },
            "author": "Kine Terada",
            "title": "The Japanese Office Dress Code",
            "description": "If you want to make a good impression when you first start working at a company in Japan, then it is important to stick closely to the standard Japanese office dress code. The main rule if you are new to a company is to choose basic and simple designs for your outfit",
            "tag": [
                "Japan",
                "Culture"
            ],
            "urlToImage": "https://workinjapan.today/wp-content/uploads/2019/12/Business-dress-code-in-Japan_fixed-b-825x550.jpg",
            "url": "https://oishya.com/journal/secrets-of-japanese-business-etiquette-business-meetings/",
            "publishedAt": "2022-12-12T08:00:40Z",
            "content": "The 80/20 productivity rule is a prime example that most times, it’s best to work smarter over working harder. When you carefully distribute your time and energy based on this rule, you can be more productive in your business.",
            "createdAt": "2022-12-17T00:56:50.770Z",
            "updatedAt": "2022-12-17T00:56:50.770Z",
            "__v": 0,
            "category": "Working"
        },
        {
            "_id": "639c39e11b6c267e3be06e4a",
            "source": {
                "name": "Post Magazine",
                "id": "639c39e11b6c267e3be06e48"
            },
            "author": "Kine Terada",
            "title": "Surviving Japanese Work Culture",
            "description": "Working in Japan is NOT like working back home. Do you remember the first time you set foot in Japan? You had training with your first company, went to your first apartment, went shopping by",
            "tag": [
                "Japan",
                "Culture"
            ],
            "urlToImage": "https://i.shgcdn.com/5d6cef81-4d39-4201-a212-2577c1627256/-/format/auto/-/preview/3000x3000/-/quality/lighter/",
            "url": "https://oishya.com/journal/secrets-of-japanese-business-etiquette-business-meetings/",
            "publishedAt": "2022-12-12T08:00:40Z",
            "content": "The 80/20 productivity rule is a prime example that most times, it’s best to work smarter over working harder. When you carefully distribute your time and energy based on this rule, you can be more productive in your business.",
            "createdAt": "2022-12-16T09:26:57.730Z",
            "updatedAt": "2022-12-16T09:26:57.730Z",
            "__v": 0,
            "category": "Working"
        },
        {
            "_id": "639c39801b6c267e3be06e46",
            "source": {
                "name": "Post Magazine",
                "id": "639c39801b6c267e3be06e44"
            },
            "author": "Kine Terada",
            "title": "Secrets of Japanese Business Etiquette: Business Meetings",
            "description": "This blog is the fourth one in our series of Secrets of Japanese Business Etiquette (How not to Faux Pas). In particular, it will clear any confusion relating to business meetings in Japan. ",
            "tag": [
                "Japan",
                "Meeting"
            ],
            "urlToImage": "https://cdn.oishya.com/wp-content/uploads/2019/12/sitting-arrangement-japanese-business.jpg.webp",
            "url": "https://oishya.com/journal/secrets-of-japanese-business-etiquette-business-meetings/",
            "publishedAt": "2022-12-12T08:00:40Z",
            "content": "The 80/20 productivity rule is a prime example that most times, it’s best to work smarter over working harder. When you carefully distribute your time and energy based on this rule, you can be more productive in your business.",
            "createdAt": "2022-12-16T09:25:20.319Z",
            "updatedAt": "2022-12-16T09:25:20.319Z",
            "__v": 0,
            "category": "Working"
        }
    ],
    "travel": [
        {
            "_id": "63ad54b1555b6791158c4eee",
            "source": {
                "name": "Vsenpai",
                "id": "63ad54b1555b6791158c4eec"
            },
            "slug": "le-hoi-cau-may-man-tai-nhat",
            "author": "Minh Tâm",
            "title": "Những lễ hội cầu may mắn của người Nhật bản",
            "description": "Hatsumōde: Đây là lần đầu tiên thăm đền thờ trong năm, và nhiều người đến các đền thờ và chùa để cầu xin may mắn trong năm mới.",
            "tag": [
                "Du lịch",
                "Lễ hội Nhật bản",
                "Năm mới",
                "Lễ hội Nagasaki Kunchi"
            ],
            "urlToImage": "https://images.unsplash.com/photo-1628523197648-74f46812c7d7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1742&q=80",
            "url": "AI",
            "publishedAt": "2022-12-28T08:00:40Z",
            "category": "Giải trí",
            "createdAt": "2022-12-29T08:49:53.811Z",
            "updatedAt": "2022-12-29T08:49:53.811Z",
            "__v": 0
        },
        {
            "_id": "63abec9be59537ac0a2299bf",
            "source": {
                "name": "Vsenpai",
                "id": "63abec9be59537ac0a2299bd"
            },
            "author": "Minh Tâm",
            "title": "Những lễ hội đầu năm mới cầu may mắn của người Nhật bản",
            "description": "Hatsumōde: Đây là lần đầu tiên thăm đền thờ trong năm, và nhiều người đến các đền thờ và chùa để cầu xin may mắn trong năm mới.",
            "tag": [
                "Du lịch",
                "Lễ hội Nhật bản",
                "Năm mới",
                "Lễ hội Nagasaki Kunchi"
            ],
            "urlToImage": "https://images.unsplash.com/photo-1628523197648-74f46812c7d7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1742&q=80",
            "url": "AI",
            "publishedAt": "2022-12-28T08:00:40Z",
            "category": "Giải trí",
            "createdAt": "2022-12-28T07:13:31.889Z",
            "updatedAt": "2022-12-28T07:13:31.889Z",
            "__v": 0
        },
        {
            "_id": "63abec82e59537ac0a2299bb",
            "source": {
                "name": "Vsenpai",
                "id": "63abec82e59537ac0a2299b9"
            },
            "author": "Minh Tâm",
            "title": "Những lễ hội đầu năm mới cầu may mắn của người Nhật bản",
            "description": "Hatsumōde: Đây là lần đầu tiên thăm đền thờ trong năm, và nhiều người đến các đền thờ và chùa để cầu xin may mắn trong năm mới.",
            "tag": [
                "Du lịch",
                "Lễ hội Nhật bản",
                "Năm mới",
                "Năm mới",
                "Lễ hội Nagasaki Kunchi"
            ],
            "urlToImage": "https://images.unsplash.com/photo-1628523197648-74f46812c7d7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1742&q=80",
            "url": "AI",
            "publishedAt": "2022-12-28T08:00:40Z",
            "category": "Giải trí",
            "createdAt": "2022-12-28T07:13:06.808Z",
            "updatedAt": "2022-12-28T07:13:06.808Z",
            "__v": 0
        },
        {
            "_id": "63abade050ceff4104cfa4c2",
            "source": {
                "name": "Vsenpai",
                "id": "63abade050ceff4104cfa4c0"
            },
            "author": "Minh Tâm",
            "title": "Những lễ hội đầu năm mới cầu may mắn của người Nhật bản",
            "description": "Hatsumōde: Đây là lần đầu tiên thăm đền thờ trong năm, và nhiều người đến các đền thờ và chùa để cầu xin may mắn trong năm mới.",
            "tag": [
                "Lễ hội",
                "Năm mới"
            ],
            "urlToImage": "https://images.unsplash.com/photo-1628523197648-74f46812c7d7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1742&q=80",
            "url": "AI",
            "publishedAt": "2022-12-28T08:00:40Z",
            "category": "Giải trí",
            "createdAt": "2022-12-28T02:45:52.572Z",
            "updatedAt": "2022-12-28T02:45:52.572Z",
            "__v": 0
        },
        {
            "_id": "63aaaa2c34e810c39cec6e49",
            "source": {
                "name": "Vsenpai Magazine",
                "id": "63aaaa2c34e810c39cec6e47"
            },
            "author": "A KAY",
            "title": "Hoà mình cùng thiên nhiên ở Nagano",
            "description": "Nhật Bản có rất nhiều địa điểm du lịch tuyệt vời trong mùa đông. Một số địa điểm du lịch đáng chú ý trong mùa đông bao gồm:",
            "tag": [
                "Nhật bản",
                "Onsen",
                "Du lịch"
            ],
            "urlToImage": "https://images.unsplash.com/photo-1499715008769-aa2cf0aaad5b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80",
            "url": "AI",
            "publishedAt": "2022-12-22T08:00:40Z",
            "category": "Giải trí",
            "createdAt": "2022-12-27T08:17:48.690Z",
            "updatedAt": "2022-12-27T08:17:48.690Z",
            "__v": 0
        },
        {
            "_id": "63aaa79e34e810c39cec6e1d",
            "source": {
                "name": "Vsenpai Magazine",
                "id": "63aaa79e34e810c39cec6e1b"
            },
            "author": "A KAY",
            "title": "Hoà mình cùng thiên nhiên ở Nagano",
            "description": "Nhật Bản có rất nhiều địa điểm du lịch tuyệt vời trong mùa đông. Một số địa điểm du lịch đáng chú ý trong mùa đông bao gồm:",
            "tag": [
                "Nhật bản",
                "Onsen",
                "Du lịch"
            ],
            "urlToImage": "https://images.unsplash.com/photo-1499715008769-aa2cf0aaad5b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80",
            "url": "AI",
            "publishedAt": "2022-12-22T08:00:40Z",
            "category": "Giải trí",
            "createdAt": "2022-12-27T08:06:54.737Z",
            "updatedAt": "2022-12-27T08:06:54.737Z",
            "__v": 0
        },
        {
            "_id": "63aaa73034e810c39cec6e11",
            "source": {
                "name": "Vsenpai Magazine",
                "id": "63aaa73034e810c39cec6e0f"
            },
            "author": "A KAY",
            "title": "Hoà mình cùng thiên nhiên ở Nagano",
            "description": "Nhật Bản có rất nhiều địa điểm du lịch tuyệt vời trong mùa đông. Một số địa điểm du lịch đáng chú ý trong mùa đông bao gồm:",
            "tag": [
                "Nhật bản",
                "Onsen",
                "Du lịch"
            ],
            "urlToImage": "https://images.unsplash.com/photo-1499715008769-aa2cf0aaad5b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80",
            "url": "AI",
            "publishedAt": "2022-12-22T08:00:40Z",
            "category": "Giải trí",
            "createdAt": "2022-12-27T08:05:04.732Z",
            "updatedAt": "2022-12-27T08:05:04.732Z",
            "__v": 0
        },
        {
            "_id": "63a42b9e3f613fff8f2bea42",
            "source": {
                "name": "Post Magazine",
                "id": "63a42b9e3f613fff8f2bea40"
            },
            "author": "A KAY",
            "title": "Du lịch Nhật bản mùa đông",
            "description": "Nhật Bản có rất nhiều địa điểm du lịch tuyệt vời trong mùa đông. Một số địa điểm du lịch đáng chú ý trong mùa đông bao gồm:",
            "tag": [
                "Japan",
                "Culture"
            ],
            "urlToImage": "https://images.unsplash.com/photo-1540959733332-eab4deabeeaf?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1494&q=80",
            "url": "AI",
            "publishedAt": "2022-12-22T08:00:40Z",
            "content": "Live from the New York Stock Exchange, TheStreet's J.D. Durkin and Martin Baccardax discuss the major markets stories that ... \r\n#martinbaccardax#thestreet#jddurkin\r\n This story appeared first on you… [+20 chars]",
            "category": "Travel",
            "createdAt": "2022-12-22T10:04:14.253Z",
            "updatedAt": "2022-12-22T10:04:14.253Z",
            "__v": 0
        },
        {
            "_id": "639c2db5024d0ffc7b4c2f79",
            "source": {
                "name": "Post Magazine",
                "id": "639c2db5024d0ffc7b4c2f77"
            },
            "author": "Kine Terada",
            "title": "101 best things to do in Tokyo",
            "description": "Enjoy the city at its most sparkly – when Tokyo switches on its festive lights and illuminations in winter",
            "tag": [],
            "urlToImage": "https://media.timeout.com/images/105848927/1372/772/image.jpg",
            "url": "https://www.timeout.com/tokyo/things-to-do/tokyo-illuminations",
            "publishedAt": "2022-12-12T08:00:40Z",
            "content": "The 80/20 productivity rule is a prime example that most times, it’s best to work smarter over working harder. When you carefully distribute your time and energy based on this rule, you can be more productive in your business.",
            "category": "travel",
            "createdAt": "2022-12-16T08:35:01.039Z",
            "updatedAt": "2022-12-16T08:35:01.039Z",
            "__v": 0
        }
    ],
    "job": [
        {
            "_id": "639d2b8c1b6bb9e3d79c69a3",
            "source": {
                "name": "Post Magazine",
                "id": "639d2b8c1b6bb9e3d79c69a1"
            },
            "author": "en world Japan K.K",
            "title": "National Consultancy - Special Advisor, Partnership (part-time), PPD Tokyo...",
            "description": "If you want to make a good impression when you first start working at a company in Japan, then it is important to stick closely to the standard Japanese office dress code. The main rule if you are new to a company is to choose basic and simple designs for your outfit",
            "tag": [
                "Japan",
                "Culture"
            ],
            "urlToImage": "https://pbs.twimg.com/profile_images/808330362417979392/AdiQ86lk_400x400.jpg",
            "url": "https://oishya.com/journal/secrets-of-japanese-business-etiquette-business-meetings/",
            "publishedAt": "2022-12-12T08:00:40Z",
            "content": "The 80/20 productivity rule is a prime example that most times, it’s best to work smarter over working harder. When you carefully distribute your time and energy based on this rule, you can be more productive in your business.",
            "category": "Job",
            "workingType": "Hybrid",
            "createdAt": "2022-12-17T02:38:04.296Z",
            "updatedAt": "2022-12-17T02:38:04.296Z",
            "__v": 0
        },
        {
            "_id": "639d2b681b6bb9e3d79c699f",
            "source": {
                "name": "Post Magazine",
                "id": "639d2b681b6bb9e3d79c699d"
            },
            "author": "en world Japan K.K",
            "title": "House cleaning / repair / miscellaneous construction staff",
            "description": "If you want to make a good impression when you first start working at a company in Japan, then it is important to stick closely to the standard Japanese office dress code. The main rule if you are new to a company is to choose basic and simple designs for your outfit",
            "tag": [
                "Japan",
                "Culture"
            ],
            "urlToImage": "https://aglobalharmony.info/wp-content/uploads/2020/04/aghimg.jpg",
            "url": "https://oishya.com/journal/secrets-of-japanese-business-etiquette-business-meetings/",
            "publishedAt": "2022-12-12T08:00:40Z",
            "content": "The 80/20 productivity rule is a prime example that most times, it’s best to work smarter over working harder. When you carefully distribute your time and energy based on this rule, you can be more productive in your business.",
            "category": "Job",
            "workingType": "Hybrid",
            "createdAt": "2022-12-17T02:37:28.716Z",
            "updatedAt": "2022-12-17T02:37:28.716Z",
            "__v": 0
        },
        {
            "_id": "639d2b3d1b6bb9e3d79c699b",
            "source": {
                "name": "Post Magazine",
                "id": "639d2b3d1b6bb9e3d79c6999"
            },
            "author": "en world Japan K.K",
            "title": "Clerical work of sales, the support of sales staff",
            "description": "If you want to make a good impression when you first start working at a company in Japan, then it is important to stick closely to the standard Japanese office dress code. The main rule if you are new to a company is to choose basic and simple designs for your outfit",
            "tag": [
                "Japan",
                "Culture"
            ],
            "urlToImage": "https://aglobalharmony.info/wp-content/uploads/2020/04/aghimg.jpg",
            "url": "https://oishya.com/journal/secrets-of-japanese-business-etiquette-business-meetings/",
            "publishedAt": "2022-12-12T08:00:40Z",
            "content": "The 80/20 productivity rule is a prime example that most times, it’s best to work smarter over working harder. When you carefully distribute your time and energy based on this rule, you can be more productive in your business.",
            "category": "Job",
            "workingType": "Hybrid",
            "createdAt": "2022-12-17T02:36:45.569Z",
            "updatedAt": "2022-12-17T02:36:45.569Z",
            "__v": 0,
            "salary": "2500yen/hour"
        },
        {
            "_id": "639d288a0c5f0ca5a2a8e256",
            "source": {
                "name": "Post Magazine",
                "id": "639d288a0c5f0ca5a2a8e254"
            },
            "author": "en world Japan K.K",
            "title": "Airport duty free store staff for tourists visiting Japan",
            "description": "If you want to make a good impression when you first start working at a company in Japan, then it is important to stick closely to the standard Japanese office dress code. The main rule if you are new to a company is to choose basic and simple designs for your outfit",
            "tag": [
                "Japan",
                "Culture"
            ],
            "urlToImage": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSLWcqlfrLNjigFB6pbqHIyCnwyCDifQjUipw&usqp=CAU",
            "url": "https://oishya.com/journal/secrets-of-japanese-business-etiquette-business-meetings/",
            "publishedAt": "2022-12-12T08:00:40Z",
            "content": "The 80/20 productivity rule is a prime example that most times, it’s best to work smarter over working harder. When you carefully distribute your time and energy based on this rule, you can be more productive in your business.",
            "category": "Job",
            "createdAt": "2022-12-17T02:25:14.622Z",
            "updatedAt": "2022-12-17T02:25:14.622Z",
            "__v": 0,
            "workingType": "Remote"
        },
        {
            "_id": "639d283f0c5f0ca5a2a8e252",
            "source": {
                "name": "Post Magazine",
                "id": "639d283f0c5f0ca5a2a8e250"
            },
            "author": "en world Japan K.K",
            "title": "Part-time English Teacher for Children",
            "description": "If you want to make a good impression when you first start working at a company in Japan, then it is important to stick closely to the standard Japanese office dress code. The main rule if you are new to a company is to choose basic and simple designs for your outfit",
            "tag": [
                "Japan",
                "Culture"
            ],
            "urlToImage": "http://sesamestreetenglish.com/sites/default/files/1516394925/SSEG_Main.jpg",
            "url": "https://oishya.com/journal/secrets-of-japanese-business-etiquette-business-meetings/",
            "publishedAt": "2022-12-12T08:00:40Z",
            "content": "The 80/20 productivity rule is a prime example that most times, it’s best to work smarter over working harder. When you carefully distribute your time and energy based on this rule, you can be more productive in your business.",
            "category": "Job",
            "createdAt": "2022-12-17T02:23:59.687Z",
            "updatedAt": "2022-12-17T02:23:59.687Z",
            "__v": 0,
            "workingType": "Remote"
        },
        {
            "_id": "639d27f10c5f0ca5a2a8e24e",
            "source": {
                "name": "Post Magazine",
                "id": "639d27f10c5f0ca5a2a8e24c"
            },
            "author": "en world Japan K.K",
            "title": "Part-Time teacher of English -- Tokyo and Surrounding Region",
            "description": "If you want to make a good impression when you first start working at a company in Japan, then it is important to stick closely to the standard Japanese office dress code. The main rule if you are new to a company is to choose basic and simple designs for your outfit",
            "tag": [
                "Japan",
                "Culture"
            ],
            "urlToImage": "https://casio.jp/business/case/media/jp_ja/business/images/dpj/41/img01.jpg",
            "url": "https://oishya.com/journal/secrets-of-japanese-business-etiquette-business-meetings/",
            "publishedAt": "2022-12-12T08:00:40Z",
            "content": "The 80/20 productivity rule is a prime example that most times, it’s best to work smarter over working harder. When you carefully distribute your time and energy based on this rule, you can be more productive in your business.",
            "category": "Job",
            "createdAt": "2022-12-17T02:22:41.926Z",
            "updatedAt": "2022-12-17T02:22:41.926Z",
            "__v": 0,
            "workingType": "Remote"
        },
        {
            "_id": "639d25d1389e5bb9c158c800",
            "source": {
                "name": "Post Magazine",
                "id": "639d25d1389e5bb9c158c7fe"
            },
            "author": "en world Japan K.K",
            "title": "Online Data Rater - Japan (Part-time/Remote)",
            "description": "If you want to make a good impression when you first start working at a company in Japan, then it is important to stick closely to the standard Japanese office dress code. The main rule if you are new to a company is to choose basic and simple designs for your outfit",
            "tag": [
                "Japan",
                "Culture"
            ],
            "urlToImage": "https://casio.jp/business/case/media/jp_ja/business/images/dpj/41/img01.jpg",
            "url": "https://oishya.com/journal/secrets-of-japanese-business-etiquette-business-meetings/",
            "publishedAt": "2022-12-12T08:00:40Z",
            "content": "The 80/20 productivity rule is a prime example that most times, it’s best to work smarter over working harder. When you carefully distribute your time and energy based on this rule, you can be more productive in your business.",
            "category": "Job",
            "createdAt": "2022-12-17T02:13:37.982Z",
            "updatedAt": "2022-12-17T02:13:37.982Z",
            "__v": 0,
            "workingType": "Remote"
        },
        {
            "_id": "639d2510389e5bb9c158c7fc",
            "source": {
                "name": "Post Magazine",
                "id": "639d2510389e5bb9c158c7fa"
            },
            "author": "en world Japan K.K",
            "title": "Part-Time Paralegal Assistant at Global Manufacturer",
            "description": "If you want to make a good impression when you first start working at a company in Japan, then it is important to stick closely to the standard Japanese office dress code. The main rule if you are new to a company is to choose basic and simple designs for your outfit",
            "tag": [
                "Japan",
                "Culture"
            ],
            "urlToImage": "https://namchauims.com/wp-content/uploads/2019/06/du-hoc-sinh-lam-them-tai-nhat-ban.jpg",
            "url": "https://oishya.com/journal/secrets-of-japanese-business-etiquette-business-meetings/",
            "publishedAt": "2022-12-12T08:00:40Z",
            "content": "The 80/20 productivity rule is a prime example that most times, it’s best to work smarter over working harder. When you carefully distribute your time and energy based on this rule, you can be more productive in your business.",
            "category": "Job",
            "createdAt": "2022-12-17T02:10:24.784Z",
            "updatedAt": "2022-12-17T02:10:24.784Z",
            "__v": 0,
            "workingType": "Remote"
        }
    ],
    "event": [
        {
            "_id": "639d2f6a7dc6ffd30769b5a5",
            "source": {
                "name": "Post Magazine",
                "id": "639d2f6a7dc6ffd30769b5a3"
            },
            "author": "en world Japan K.K",
            "title": "Nishiarai Daishi Temple – End of Year Festival",
            "description": "One of Tokyo’s biggest and most frequent flea markets, this event takes place over many — if not all — weekends a month. Also known as the Tokyo City Flea Market, it can feature up to 600 (!) vendors on a busy day, making it a great place to hunt for bargains.",
            "tag": [
                "Japan",
                "Culture"
            ],
            "urlToImage": "https://cdn.cheapoguides.com/wp-content/uploads/sites/2/2014/11/Rake-Festival-Istock-Yukihipo-1280x600.jpg",
            "url": "https://oishya.com/journal/secrets-of-japanese-business-etiquette-business-meetings/",
            "publishedAt": "2022-12-12T08:00:40Z",
            "content": "The 80/20 productivity rule is a prime example that most times, it’s best to work smarter over working harder. When you carefully distribute your time and energy based on this rule, you can be more productive in your business.",
            "category": "Event",
            "location": "Chyoda, Tokyo",
            "createdAt": "2022-12-17T02:54:34.497Z",
            "updatedAt": "2022-12-17T02:54:34.497Z",
            "__v": 0
        },
        {
            "_id": "639d2f477dc6ffd30769b5a1",
            "source": {
                "name": "Post Magazine",
                "id": "639d2f477dc6ffd30769b59f"
            },
            "author": "en world Japan K.K",
            "title": "he Oedo Antique Market",
            "description": "One of Tokyo’s biggest and most frequent flea markets, this event takes place over many — if not all — weekends a month. Also known as the Tokyo City Flea Market, it can feature up to 600 (!) vendors on a busy day, making it a great place to hunt for bargains.",
            "tag": [
                "Japan",
                "Culture"
            ],
            "urlToImage": "https://cdn.cheapoguides.com/wp-content/uploads/sites/2/2014/12/Oedo-Antique-Market-CF-1280x600.jpg",
            "url": "https://oishya.com/journal/secrets-of-japanese-business-etiquette-business-meetings/",
            "publishedAt": "2022-12-12T08:00:40Z",
            "content": "The 80/20 productivity rule is a prime example that most times, it’s best to work smarter over working harder. When you carefully distribute your time and energy based on this rule, you can be more productive in your business.",
            "category": "Event",
            "location": "Chyoda, Tokyo",
            "createdAt": "2022-12-17T02:53:59.566Z",
            "updatedAt": "2022-12-17T02:53:59.566Z",
            "__v": 0
        },
        {
            "_id": "639d2f1f7dc6ffd30769b59d",
            "source": {
                "name": "Post Magazine",
                "id": "639d2f1f7dc6ffd30769b59b"
            },
            "author": "en world Japan K.K",
            "title": "Ohi Racecourse Flea Market",
            "description": "One of Tokyo’s biggest and most frequent flea markets, this event takes place over many — if not all — weekends a month. Also known as the Tokyo City Flea Market, it can feature up to 600 (!) vendors on a busy day, making it a great place to hunt for bargains.",
            "tag": [
                "Japan",
                "Culture"
            ],
            "urlToImage": "https://cdn.cheapoguides.com/wp-content/uploads/sites/2/2019/10/nogi-shrine-antique-market_gdl-1280x600.jpg",
            "url": "https://oishya.com/journal/secrets-of-japanese-business-etiquette-business-meetings/",
            "publishedAt": "2022-12-12T08:00:40Z",
            "content": "The 80/20 productivity rule is a prime example that most times, it’s best to work smarter over working harder. When you carefully distribute your time and energy based on this rule, you can be more productive in your business.",
            "category": "Event",
            "location": "Koto, Tokyo",
            "createdAt": "2022-12-17T02:53:19.846Z",
            "updatedAt": "2022-12-17T02:53:19.846Z",
            "__v": 0
        }
    ],
    "studyAb": [
        {
            "_id": "639dcf4924130088697a1f7c",
            "source": {
                "name": "Post Magazine",
                "id": "639dcf4924130088697a1f7a"
            },
            "author": "en world Japan K.K",
            "title": "What to Know Before Studying Abroad in Japan",
            "description": "Studying abroad was the best experience I had during college. I was able to live in Paris and attend a university there as an exchange student. Those are some of my favorite memories!",
            "tag": [
                "Japan",
                "Culture"
            ],
            "urlToImage": "https://thesmartlocal.jp/wp-content/uploads/2022/06/Studying-In-Japan-Guide-18.jpeg",
            "url": "https://oishya.com/journal/secrets-of-japanese-business-etiquette-business-meetings/",
            "publishedAt": "2022-12-12T08:00:40Z",
            "content": "The 80/20 productivity rule is a prime example that most times, it’s best to work smarter over working harder. When you carefully distribute your time and energy based on this rule, you can be more productive in your business.",
            "category": "Study Abroad",
            "createdAt": "2022-12-17T14:16:41.320Z",
            "updatedAt": "2022-12-17T14:16:41.320Z",
            "__v": 0
        },
        {
            "_id": "639dcef924130088697a1f73",
            "source": {
                "name": "Post Magazine",
                "id": "639dcef924130088697a1f71"
            },
            "author": "en world Japan K.K",
            "title": "Studying In Japan: 20 Tips Foreign Students Must Know, From Applying To Universities To Living There",
            "description": "Studying abroad was the best experience I had during college. I was able to live in Paris and attend a university there as an exchange student. Those are some of my favorite memories!",
            "tag": [
                "Japan",
                "Culture"
            ],
            "urlToImage": "https://thesmartlocal.jp/wp-content/uploads/2022/06/800px-Japanese_Visa.jpeg",
            "url": "https://oishya.com/journal/secrets-of-japanese-business-etiquette-business-meetings/",
            "publishedAt": "2022-12-12T08:00:40Z",
            "content": "The 80/20 productivity rule is a prime example that most times, it’s best to work smarter over working harder. When you carefully distribute your time and energy based on this rule, you can be more productive in your business.",
            "category": "Study Abroad",
            "createdAt": "2022-12-17T14:15:21.821Z",
            "updatedAt": "2022-12-17T14:15:21.821Z",
            "__v": 0
        },
        {
            "_id": "639dcecc24130088697a1f6f",
            "source": {
                "name": "Post Magazine",
                "id": "639dcecc24130088697a1f6d"
            },
            "author": "en world Japan K.K",
            "title": "Study Abroad in Japan: Essential Travel Items for Students",
            "description": "Studying abroad was the best experience I had during college. I was able to live in Paris and attend a university there as an exchange student. Those are some of my favorite memories!",
            "tag": [
                "Japan",
                "Culture"
            ],
            "urlToImage": "https://storage.googleapis.com/jpn-new-wp/uploads/2021/08/955af52d-clothes-bag-shoes.jpeg",
            "url": "https://oishya.com/journal/secrets-of-japanese-business-etiquette-business-meetings/",
            "publishedAt": "2022-12-12T08:00:40Z",
            "content": "The 80/20 productivity rule is a prime example that most times, it’s best to work smarter over working harder. When you carefully distribute your time and energy based on this rule, you can be more productive in your business.",
            "category": "Study Abroad",
            "createdAt": "2022-12-17T14:14:36.321Z",
            "updatedAt": "2022-12-17T14:14:36.321Z",
            "__v": 0
        },
        {
            "_id": "639dce6a24130088697a1f66",
            "source": {
                "name": "Post Magazine",
                "id": "639dce6a24130088697a1f64"
            },
            "author": "en world Japan K.K",
            "title": "Study Abroad in Japan: 6 Tips to Prepare",
            "description": "Studying abroad was the best experience I had during college. I was able to live in Paris and attend a university there as an exchange student. Those are some of my favorite memories!",
            "tag": [
                "Japan",
                "Culture"
            ],
            "urlToImage": "https://schoolynk.com/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaEpJaWt5TlRWbE16VmhPUzFrTlRFekxUUTJaV0V0WVRNd05pMHpOVE13TlRrME16VmxaRElHT2daRlZBPT0iLCJleHAiOm51bGwsInB1ciI6ImJsb2JfaWQifX0=--8b7ce577a3532c74bb2c86edff138b0cd3e8493e/2021-08-16%2009-33-57%20UTC",
            "url": "https://oishya.com/journal/secrets-of-japanese-business-etiquette-business-meetings/",
            "publishedAt": "2022-12-12T08:00:40Z",
            "content": "The 80/20 productivity rule is a prime example that most times, it’s best to work smarter over working harder. When you carefully distribute your time and energy based on this rule, you can be more productive in your business.",
            "category": "Study Abroad",
            "createdAt": "2022-12-17T14:12:58.704Z",
            "updatedAt": "2022-12-17T14:12:58.704Z",
            "__v": 0
        },
        {
            "_id": "639dccb024130088697a1f49",
            "source": {
                "name": "Post Magazine",
                "id": "639dccb024130088697a1f47"
            },
            "author": "en world Japan K.K",
            "title": "26 Helpful Study Abroad Tips You Need to Thrive",
            "description": "Studying abroad was the best experience I had during college. I was able to live in Paris and attend a university there as an exchange student. Those are some of my favorite memories!",
            "tag": [
                "Japan",
                "Culture"
            ],
            "urlToImage": "https://i0.wp.com/sojournies.com/wp-content/uploads/2021/07/Study-abroad-tips.png?w=1000&ssl=1",
            "url": "https://oishya.com/journal/secrets-of-japanese-business-etiquette-business-meetings/",
            "publishedAt": "2022-12-12T08:00:40Z",
            "content": "The 80/20 productivity rule is a prime example that most times, it’s best to work smarter over working harder. When you carefully distribute your time and energy based on this rule, you can be more productive in your business.",
            "category": "Study Abroad",
            "createdAt": "2022-12-17T14:05:36.596Z",
            "updatedAt": "2022-12-17T14:05:36.596Z",
            "__v": 0
        },
        {
            "_id": "639dcc6024130088697a1f40",
            "source": {
                "name": "Post Magazine",
                "id": "639dcc6024130088697a1f3e"
            },
            "author": "en world Japan K.K",
            "title": "10 Essential Tips for Studying AbroadーThe Best Tips for Studying Abroad",
            "description": "Studying abroad was the best experience I had during college. I was able to live in Paris and attend a university there as an exchange student. Those are some of my favorite memories!",
            "tag": [
                "Japan",
                "Culture"
            ],
            "urlToImage": "https://i0.wp.com/sojournies.com/wp-content/uploads/2020/12/Instagram-spots-in-Greece.jpg?w=1000&ssl=1",
            "url": "https://oishya.com/journal/secrets-of-japanese-business-etiquette-business-meetings/",
            "publishedAt": "2022-12-12T08:00:40Z",
            "content": "The 80/20 productivity rule is a prime example that most times, it’s best to work smarter over working harder. When you carefully distribute your time and energy based on this rule, you can be more productive in your business.",
            "category": "Study Abroad",
            "createdAt": "2022-12-17T14:04:16.273Z",
            "updatedAt": "2022-12-17T14:04:16.273Z",
            "__v": 0
        }
    ],
    "financial": [
        {
            "_id": "639dd5d7386404949ab2cc4c",
            "source": {
                "name": "Post Magazine",
                "id": "639dd5d7386404949ab2cc4a"
            },
            "author": "en world Japan K.K",
            "title": "Japan approves major defense overhaul in dramatic policy shift",
            "description": "https://image.cnbcfm.com/api/v1/image/106799968-1605831333900-GettyImages-1229675701.jpg?v=1671148238&w=740&h=416&ffmt=webp&vtcrop=y",
            "tag": [
                "Japan",
                "Culture"
            ],
            "urlToImage": "https://www.japantimes.co.jp/wp-content/uploads/2022/12/np_file_199825.jpeg",
            "url": "https://oishya.com/journal/secrets-of-japanese-business-etiquette-business-meetings/",
            "publishedAt": "2022-12-12T08:00:40Z",
            "content": "The 80/20 productivity rule is a prime example that most times, it’s best to work smarter over working harder. When you carefully distribute your time and energy based on this rule, you can be more productive in your business.",
            "category": "Financial",
            "createdAt": "2022-12-17T14:44:39.876Z",
            "updatedAt": "2022-12-17T14:44:39.876Z",
            "__v": 0
        },
        {
            "_id": "639dd3e1386404949ab2cc08",
            "source": {
                "name": "Post Magazine",
                "id": "639dd3e1386404949ab2cc06"
            },
            "author": "en world Japan K.K",
            "title": "apan’s economy could enter a recession in 2023, economist says",
            "description": "https://image.cnbcfm.com/api/v1/image/106799968-1605831333900-GettyImages-1229675701.jpg?v=1671148238&w=740&h=416&ffmt=webp&vtcrop=y",
            "tag": [
                "Japan",
                "Culture"
            ],
            "urlToImage": "https://image.cnbcfm.com/api/v1/image/107162264-1670379611145-gettyimages-1243657434-JAPAN_TANKAN.jpeg?v=1670389921&w=740&h=416&ffmt=webp&vtcrop=y",
            "url": "https://oishya.com/journal/secrets-of-japanese-business-etiquette-business-meetings/",
            "publishedAt": "2022-12-12T08:00:40Z",
            "content": "The 80/20 productivity rule is a prime example that most times, it’s best to work smarter over working harder. When you carefully distribute your time and energy based on this rule, you can be more productive in your business.",
            "category": "Financial",
            "createdAt": "2022-12-17T14:36:17.235Z",
            "updatedAt": "2022-12-17T14:36:17.235Z",
            "__v": 0
        },
        {
            "_id": "639dd357386404949ab2cbfc",
            "source": {
                "name": "Post Magazine",
                "id": "639dd357386404949ab2cbfa"
            },
            "author": "en world Japan K.K",
            "title": "Asia-Pacific markets trade lower as recession fears grow",
            "description": "https://image.cnbcfm.com/api/v1/image/106799968-1605831333900-GettyImages-1229675701.jpg?v=1671148238&w=740&h=416&ffmt=webp&vtcrop=y",
            "tag": [
                "Japan",
                "Culture"
            ],
            "urlToImage": "https://cdn-japantimes.com/wp-content/uploads/2022/12/np_file_199910.jpeg",
            "url": "https://oishya.com/journal/secrets-of-japanese-business-etiquette-business-meetings/",
            "publishedAt": "2022-12-12T08:00:40Z",
            "content": "The 80/20 productivity rule is a prime example that most times, it’s best to work smarter over working harder. When you carefully distribute your time and energy based on this rule, you can be more productive in your business.",
            "category": "Financial",
            "createdAt": "2022-12-17T14:33:59.180Z",
            "updatedAt": "2022-12-17T14:33:59.180Z",
            "__v": 0
        }
    ],
    "personalf": [
        {
            "_id": "639dd45b386404949ab2cc10",
            "source": {
                "name": "Post Magazine",
                "id": "639dd45b386404949ab2cc0e"
            },
            "author": "en world Japan K.K",
            "title": "What is Personal Finance? Importance, Types, Process, and Strategies",
            "description": "Studying abroad was the best experience I had during college. I was able to live in Paris and attend a university there as an exchange student. Those are some of my favorite memories!",
            "tag": [
                "Japan",
                "Culture"
            ],
            "urlToImage": "https://www.analyticssteps.com/backend/media/thumbnail/8331994/8586366_1604483626_icons.jpg",
            "url": "https://oishya.com/journal/secrets-of-japanese-business-etiquette-business-meetings/",
            "publishedAt": "2022-12-12T08:00:40Z",
            "content": "The 80/20 productivity rule is a prime example that most times, it’s best to work smarter over working harder. When you carefully distribute your time and energy based on this rule, you can be more productive in your business.",
            "category": "Personal Financial",
            "createdAt": "2022-12-17T14:38:19.800Z",
            "updatedAt": "2022-12-17T14:38:19.800Z",
            "__v": 0
        },
        {
            "_id": "639dd43b386404949ab2cc0c",
            "source": {
                "name": "Post Magazine",
                "id": "639dd43b386404949ab2cc0a"
            },
            "author": "en world Japan K.K",
            "title": "Areas of Personal Finance",
            "description": "Studying abroad was the best experience I had during college. I was able to live in Paris and attend a university there as an exchange student. Those are some of my favorite memories!",
            "tag": [
                "Japan",
                "Culture"
            ],
            "urlToImage": "https://cdn.corporatefinanceinstitute.com/assets/personal-finance-planning.jpg",
            "url": "https://oishya.com/journal/secrets-of-japanese-business-etiquette-business-meetings/",
            "publishedAt": "2022-12-12T08:00:40Z",
            "content": "The 80/20 productivity rule is a prime example that most times, it’s best to work smarter over working harder. When you carefully distribute your time and energy based on this rule, you can be more productive in your business.",
            "category": "Personal Financial",
            "createdAt": "2022-12-17T14:37:47.482Z",
            "updatedAt": "2022-12-17T14:37:47.482Z",
            "__v": 0
        },
        {
            "_id": "639dd3a7386404949ab2cc04",
            "source": {
                "name": "Post Magazine",
                "id": "639dd3a6386404949ab2cc02"
            },
            "author": "en world Japan K.K",
            "title": "Hong Kong stocks rise around 3% after reports say city is considering Covid rule easing",
            "description": "Studying abroad was the best experience I had during college. I was able to live in Paris and attend a university there as an exchange student. Those are some of my favorite memories!",
            "tag": [
                "Japan",
                "Culture"
            ],
            "urlToImage": "https://image.cnbcfm.com/api/v1/image/107163082-1670467756277-gettyimages-1244986812-AFP_32Q26WX.jpeg?v=1670468375&w=740&h=416&ffmt=webp&vtcrop=y",
            "url": "https://oishya.com/journal/secrets-of-japanese-business-etiquette-business-meetings/",
            "publishedAt": "2022-12-12T08:00:40Z",
            "content": "The 80/20 productivity rule is a prime example that most times, it’s best to work smarter over working harder. When you carefully distribute your time and energy based on this rule, you can be more productive in your business.",
            "category": "Personal Financial",
            "createdAt": "2022-12-17T14:35:19.008Z",
            "updatedAt": "2022-12-17T14:35:19.008Z",
            "__v": 0
        },
        {
            "_id": "639dd382386404949ab2cc00",
            "source": {
                "name": "Post Magazine",
                "id": "639dd382386404949ab2cbfe"
            },
            "author": "en world Japan K.K",
            "title": "Marc Benioff tells Salesforce workers that new employees are ‘facing lower productivity’",
            "description": "Studying abroad was the best experience I had during college. I was able to live in Paris and attend a university there as an exchange student. Those are some of my favorite memories!",
            "tag": [
                "Japan",
                "Culture"
            ],
            "urlToImage": "https://image.cnbcfm.com/api/v1/image/107148122-1667931114380-gettyimages-1243390865-DREAMFORCE_2022.jpeg?v=1671238840&w=740&h=416&ffmt=webp&vtcrop=y",
            "url": "https://oishya.com/journal/secrets-of-japanese-business-etiquette-business-meetings/",
            "publishedAt": "2022-12-12T08:00:40Z",
            "content": "The 80/20 productivity rule is a prime example that most times, it’s best to work smarter over working harder. When you carefully distribute your time and energy based on this rule, you can be more productive in your business.",
            "category": "Personal Financial",
            "createdAt": "2022-12-17T14:34:42.404Z",
            "updatedAt": "2022-12-17T14:34:42.404Z",
            "__v": 0
        }
    ],
    "top5news": [
        {
            "_id": "63a436190144104629286e7e",
            "source": {
                "name": "Post Magazine",
                "id": "63a436190144104629286e7c"
            },
            "author": "A KAY",
            "title": "Mở tài khoản ngân hàng tại Nhật",
            "description": "Mở một tài khoản ngân hàng trực tuyến tại Nhật Bản thường khá dễ dàng, nhưng có một số yêu cầu cần đảm bảo bạn có thể hoàn thành trước khi bắt đầu quá trình mở tài khoản.",
            "tag": [
                "Tiện ích",
                "Nhật bản"
            ],
            "urlToImage": "https://i.ytimg.com/vi/lIGaKhFWkEg/maxresdefault.jpg",
            "url": "AI",
            "category": "News",
            "ad": true,
            "createdAt": "2022-12-22T10:59:57.084Z",
            "updatedAt": "2022-12-22T10:59:57.084Z",
            "__v": 0
        },
        {
            "_id": "63a436b10144104629286e9a",
            "source": {
                "name": "Post Magazine",
                "id": "63a436b10144104629286e98"
            },
            "author": "A KAY",
            "title": "Quán ăn đọc đáo tại Nhật",
            "description": "Mở một tài khoản ngân hàng trực tuyến tại Nhật Bản thường khá dễ dàng, nhưng có một số yêu cầu cần đảm bảo bạn có thể hoàn thành trước khi bắt đầu quá trình mở tài khoản.",
            "tag": [
                "Tiện ích",
                "Nhật bản"
            ],
            "urlToImage": "https://images.unsplash.com/photo-1476055439777-977cdf3a5699?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
            "url": "AI",
            "category": "News",
            "ad": true,
            "createdAt": "2022-12-22T10:59:29.891Z",
            "updatedAt": "2022-12-22T10:59:29.891Z",
            "__v": 0
        },
        {
            "_id": "63a432340144104629286e7a",
            "source": {
                "name": "Post Magazine",
                "id": "63a432340144104629286e78"
            },
            "author": "A KAY",
            "title": "Du lịch Nhật bản mùa đông",
            "description": "Nhật Bản có rất nhiều địa điểm du lịch tuyệt vời trong mùa đông. Một số địa điểm du lịch đáng chú ý trong mùa đông bao gồm:",
            "tag": [
                "Nhật bản",
                "Du lịch"
            ],
            "urlToImage": "https://images.unsplash.com/photo-1540959733332-eab4deabeeaf?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1494&q=80",
            "url": "AI",
            "publishedAt": "2022-12-22T08:00:40Z",
            "category": "News",
            "ad": true,
            "createdAt": "2022-12-22T10:53:43.648Z",
            "updatedAt": "2022-12-22T10:53:43.648Z",
            "__v": 0
        },
        {
            "_id": "63a436fb0144104629286e9e",
            "source": {
                "name": "Post Magazine",
                "id": "63a436fb0144104629286e9c"
            },
            "author": "A KAY",
            "title": "Văn hoá mặc suit tại Nhật",
            "description": "Mở một tài khoản ngân hàng trực tuyến tại Nhật Bản thường khá dễ dàng, nhưng có một số yêu cầu cần đảm bảo bạn có thể hoàn thành trước khi bắt đầu quá trình mở tài khoản.",
            "tag": [
                "Văn hoá",
                "Nhật bản"
            ],
            "urlToImage": "https://i.ytimg.com/vi/lIGaKhFWkEg/maxresdefault.jpg",
            "url": "AI",
            "category": "News",
            "ad": true,
            "createdAt": "2022-12-22T10:52:43.648Z",
            "updatedAt": "2022-12-22T10:52:43.648Z",
            "__v": 0
        }
    ],
    "tienich": [
        {
            "_id": "63ad9d10b8239e3b7873923f",
            "source": {
                "name": "Vsenpai Magazine",
                "id": "63ad9d10b8239e3b7873923d"
            },
            "slug": "mo-tai-khoan-ngan-hang-tai-nhat-ban",
            "author": "Minh tâm",
            "title": "Mở tài khoản ngân hàng tại Nhật có khó không?",
            "description": "Mở tài khoản ngân hàng tại Nhật có thể là một trong những thách thức lớn nhất khi vừa tới Nhật bản mà bạn có thể gặp phải. Ngân hàng tại nhật thường chỉ mở từ 11h trưa đến 3h chiều ngày hàng chính, nên bạn nên kiểm tra trên google chi nhánh bạn muốn đến để tới vào thời điểm ngân hàng mở cửa.",
            "tag": [
                "Tiện ích",
                "Mở tài khoản ngân hàng",
                "Chuyển tiền"
            ],
            "urlToImage": "https://i.pinimg.com/originals/82/80/68/82806895340fa9e5f71f1c6252321692.png",
            "url": "Tina",
            "publishedAt": "2022-12-22T08:00:40Z",
            "category": "Tiện ích",
            "createdAt": "2022-12-29T13:58:40.310Z",
            "updatedAt": "2022-12-29T13:58:40.310Z",
            "__v": 0
        }
    ]
}