import React from 'react';
import { Switch} from 'react-router-dom';
import PrivateRoute from '../_PrivateRoute';
import HomePageThree from "../HomePageThree";
// import BusinessThreePage from "../BusinessThreePage";
// import EntertainmentThreePage from "../EntertainmentThreePage";
// import FeatureThreePage from "../FeatureThreePage";
// import SportsThreePage from "../SportsThreePage";
// import TrendingThreePage from "../TrendingThreePage";
// import AboutUsThreePage from "../AboutUsThreePage";
// import ArchiveThreePage from "../ArchiveThreePage";
// import ContactUsThreePage from "../ContactUsThreePage";
// import NotFoundThreePage from "../NotFoundThreePage";
import PostOneHThreePage from "../PostOneHThreePage";
import TienichPost from "../TienichPost/TienichPost";
// import PostTwoHThreePage from "../PostTwoHThreePage";
// import PostThreeHThreePage from "../PostThreeHThreePage";
// import VideoPostOneHThreePage from "../VideoPostOneHThreePage";
// import VideoPostTwoHThreePage from "../VideoPostTwoHThreePage";
// import VideoPostThreeHThreePage from "../VideoPostThreeHThreePage";
// import AudioPostOneHThreePage from "../AudioPostOneHThreePage";
// import AudioPostTwoHThreePage from "../AudioPostTwoHThreePage";
// import AudioPostThreeHThreePage from "../AudioPostThreeHThreePage";
// import PostOneHThreeLeftSidebarPage from "../PostOneHThreeLeftSidebarPage";



const Routes = (props) => {
 const {items}=props
      return (

        <Switch>


    <PrivateRoute
                exact
                home_style={3}
                parentClass="theme-4"
                path="/"
                component={() => (<HomePageThree items={items} />)}
                
                />
                
            {/* <PrivateRoute
                exact
                home_style={3}
                parentClass="theme-4 bg4"
                path="/home-three/business"
                component={BusinessThreePage}/> */}
            {/* <PrivateRoute
                exact
                home_style={3}
                parentClass="theme-4 bg4"
                path="/entertainment"
                component={EntertainmentThreePage}/> */}
                 {/* <PrivateRoute
                exact
                home_style={3}
                parentClass="theme-4 bg4"
                path="/Restaurant"
                component={EntertainmentThreePage}/> */}
            {/* <PrivateRoute
                exact
                home_style={3}
                parentClass="theme-4 bg4"
                path="/home-three/features"
                component={FeatureThreePage}/> */}
            {/* <PrivateRoute
                exact
                home_style={3}
                parentClass="theme-4 bg4"
                path="/Jobs"
                component={SportsThreePage}/> */}
            {/* <PrivateRoute
                exact
                home_style={3}
                parentClass="theme-4 bg4"
                path="/home-three/trending"
                component={TrendingThreePage}/> */}
            {/* <PrivateRoute
                exact
                home_style={3}
                parentClass="theme-4 bg4"
                path="/home-three/about"
                component={AboutUsThreePage}/> */}
            {/* <PrivateRoute
                exact
                home_style={3}
                parentClass="theme-4 bg4"
                path="/home-three/archive"
                component={ArchiveThreePage}/> */}
            {/* <PrivateRoute
                exact
                home_style={3}
                parentClass="theme-4 bg4"
                path="/home-three/contact"
                component={ContactUsThreePage}/> */}
            {/* <PrivateRoute
                exact
                home_style={3}
                parentClass="theme-4 bg4"
                path="/home-three/404"
                component={NotFoundThreePage}/> */}
            <PrivateRoute
                exact
                home_style={3}
                parentClass="theme-4"
                path={`/giai-tri-nhat-ban/:id`}
                component={()=><PostOneHThreePage category="giai-tri-nhat-ban"/>}/>
                 <PrivateRoute
                exact
                home_style={3}
                parentClass="theme-4"
                path={`/tien-ich/:id`}
                component={()=><TienichPost category="tien-ich"/>}/>
            {/* <PrivateRoute
                exact

                home_style={3}
                parentClass="theme-4"
                path="/home-three/post2"
                component={PostTwoHThreePage}/> */}
            {/* <PrivateRoute
                exact
                home_style={3}
                parentClass="theme-4 theme3_bg"
                path="/home-three/post3"
                component={PostThreeHThreePage}/> */}
            {/* <PrivateRoute
                exact
                home_style={3}
                parentClass="theme-4"
                path="/home-three/video_post1"
                component={VideoPostOneHThreePage}/> */}
            {/* <PrivateRoute
                exact
                home_style={3}
                parentClass="theme-4"
                path="/home-three/video_post2"
                component={VideoPostTwoHThreePage}/> */}
            {/* <PrivateRoute
                exact
                home_style={3}
                parentClass="theme-4"
                path="/home-three/video_post3"
                component={VideoPostThreeHThreePage}/> */}
            {/* <PrivateRoute
                exact
                home_style={3}
                parentClass="theme-4"
                path="/home-three/audio_post1"
                component={AudioPostOneHThreePage }
                
                /> */}
            {/* <PrivateRoute
                exact
                home_style={3}
                parentClass="theme-4"
                path="/home-three/audio_post2"
                component={AudioPostTwoHThreePage}/> */}
            {/* <PrivateRoute
                exact
                home_style={3}
                parentClass="theme-4"
                path="/home-three/audio_post3"
                component={AudioPostThreeHThreePage}/> */}
            {/* <PrivateRoute
                exact
                home_style={3}
                parentClass="theme-4"
                path="/home-three/left_post2"
                component={PostOneHThreeLeftSidebarPage}/> */}



        </Switch>
    );
};
export default Routes