import { Link } from "react-router-dom";

import React, { useState } from "react";
import Swiper from "react-id-swiper";



const HeroArea = (props) => {
  const { items } = props;
  const [activeIndex, setActiveIndex] = useState("0");
  const params = {
    activeSlideKey: activeIndex,
    // effect: "fade",
  };
  
  return (
    <div className="wrapper_items">
      <div className="wrapper_carousel wlc_slider_demo2">
        <Swiper {...params}>
          {items.map((item, i) => (
            <div
              key={i}
              className="welcome4_area_wrap wlc_overlay"
              style={{
                background: `url(${item.urlToImage}) center/cover no-repeat`,
              }}
            >
              <div className="welcome4_area">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-7">
                        <div className="welcome_txt">
                          <p
                            className="title_meta"
                            style={{ textTransform: "uppercase" }}
                          >
                            {item.tag[0]} <span></span>
                          </p>
                          <Link to={item.source.id}>

                          <h1>{item.title}</h1>
                          </Link>

                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


          ))}

        </Swiper>
      </div>
      <div className="container d-md-block d-none">
        <div className="row">
          <div className="col-12">
            <div className="welcome_list">
              <div className="wlc_slide_demo1 d-flex">
                <div
                  className={`single_news_list ${
                    activeIndex === "0" ? "active" : ""
                  }`}
                  onClick={() => setActiveIndex("0")}
                >
                  <p>
                    <span>01</span> {items[0].tag[0]}
                  </p>
                  <h4>{items[0].title}</h4>
                </div>
                <div
                  className={`single_news_list ${
                    activeIndex === "1" ? "active" : ""
                  }`}
                  onClick={() => setActiveIndex("1")}
                >
                  <p>
                    <span>02</span> {items[1].tag[0]}
                  </p>
                  <h4>{items[1].title}</h4>
                </div>
                <div
                  className={`single_news_list ${
                    activeIndex === "2" ? "active" : ""
                  }`}
                  onClick={() => setActiveIndex("2")}
                >
                  <p>
                    <span>03</span> {items[2].tag[0]}
                  </p>
                  <h4>{items[2].title}</h4>
                </div>
                <div
                  className={`single_news_list ${
                    activeIndex === "3" ? "active" : ""
                  }`}
                  onClick={() => setActiveIndex("3")}
                >
                  <p>
                    <span>04</span> {items[3].tag[0]}
                  </p>
                  <h4>{items[3].title}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroArea;
